import { useState, useEffect } from "react";

const useProfileFieldManager = (profile, setProfile, handleSave) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldValue, setNewFieldValue] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const [added, setAdded] = useState(false);

  const handleAddField = (section) => {
    setCurrentSection(section);
    setIsModalVisible(true);
  };

  const handleSaveField = () => {
    if (newFieldName.trim()) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [currentSection]: [
          ...(prevProfile[currentSection] || []),
          { field: newFieldName, value: newFieldValue || "" }
        ]
      }));
      setIsModalVisible(false);
      setNewFieldName("");
      setNewFieldValue("");
      setAdded(true);
    }
  };

  useEffect(() => {
    if (profile && added) {
      handleSave();
      setAdded(false);
    }
  }, [added, handleSave, profile]);

  return {
    isModalVisible,
    setIsModalVisible,
    newFieldName,
    setNewFieldName,
    newFieldValue,
    setNewFieldValue,
    handleAddField,
    handleSaveField
  };
};

export default useProfileFieldManager;
