import { useState, useEffect } from "react";
import { Button, Card, Col, Grid, Modal, Row, Tabs, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient, patchClient } from "../redux/actions/clients";
import { deleteSingleAssessment } from "../redux/actions/assessments";
import AssessmentList from "../components/AssessmentList";
import ClientHeader from "../components/ClientHeader";
import FreeRecordList from "../components/FreeRecords";
import Progress from "../components/Progress";
import WorkoutSchedule from "../components/workouts/WorkoutSchedule";
import Cycles from "../components/workouts/Cycles";
import { analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { IoChevronBackOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ClientProfile from "../components/ClientProfile/ClientProfile";
import CustomSelect from "../components/common/DropDown";

const { Text } = Typography;
const { confirm } = Modal;
const { TabPane } = Tabs;

const ClientDetails = () => {
  const history = useHistory();
  const { assessments, assessmentStatus } = useSelector(
    (state) => state.assessments
  );
  const { Theme } = useSelector((state) => state.auth);

  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const fetchClient = () => dispatch(getSingleClient(client?.id));
  const [editAssessments, setEditAssessments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("assessments");

  useEffect(() => {
    if (location.state && location.state.fromWorkout) {
      setActiveTab("workouts");
    } else {
      setActiveTab("assessments");
    }
  }, [location.state]);

  // useEffect(() => {
  //   dispatch(getExercises("", [], 1, client?.id));
  // }, [client]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    if (assessmentStatus === "success") {
      fetchClient(client?.id);
    }
  }, [assessmentStatus]);

  const editAssessmentShow = (show) => {
    setEditAssessments(show);
  };

  const goToResults = () => {
    // Implement your logic for navigating to results
  };

  const deleteAssessment = (id) => {
    confirm({
      title: "Delete Assessment",
      content: "Are you sure you want to delete this assessment?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(deleteSingleAssessment(id));
      }
    });
  };
  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect once the component is mounted
    setIsFadedIn(true);
  }, []);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleGoToHome = () => {
    history.push("/");
  };

  return (
    <Row
      style={{
        minHeight: "100vh",
        marginRight: "15vw",
        position: "relative",
        bottom: "1vw"
      }}
    >
      <Col justify={"center"} align={"center"}>
        <Col
          style={{
            marginBottom: "2rem",
            position: "relative"
          }}
          className={`fade-in-slide-up-element ${
            isFadedIn ? "fade-in-slide-up-animation" : ""
          }`}
        >
          <Row
            onClick={handleGoToHome}
            style={{
              right: "8vw",
              bottom: "0vw",
              position: "relative",
              cursor: "pointer"
            }}
          >
            <IoChevronBackOutline
              style={{
                top: ".2vw",
                marginRight: ".5%",
                position: "relative",
                color: Theme.TEXT
              }}
            />
            <div>
              <Text style={{ color: Theme.TEXT }}>Back</Text>
            </div>
          </Row>
          <Row
            justify="center"
            style={{
              position: "relative"
            }}
          >
            <ClientHeader clientID={client?.id} />
          </Row>
          <Tabs
            className={Theme.BACKGROUND === "#000" ? "dark-mode" : "light-mode"}
            activeKey={activeTab}
            onChange={setActiveTab}
            tabBarStyle={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              right: "8vw",
              bottom: 75,
              outline: "none"
            }}
          >
            <TabPane tab="Assessments" key="assessments">
              <Col style={{ bottom: 75 }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: flexDirection,
                    justifyContent: "center",
                    position: "relative"
                  }}
                >
                  <Col
                    span={12}
                    style={{
                      right: isRowLayout ? "8vw" : null,
                      position: "relative",
                      animation: "fade-in 10s forwards"
                    }}
                  >
                    <AssessmentList
                      clientID={client?.id}
                      assessments={assessments}
                      editAssessmentShow={editAssessmentShow}
                      deleteAssessment={deleteAssessment}
                      goToResults={goToResults}
                    />
                  </Col>
                  {!client?.isGuest ? (
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative"
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <Progress clientID={client?.id} />
                    </Col>
                  ) : (
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative"
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <FreeRecordList
                        clientID={client?.id}
                        assessments={assessments}
                        editAssessmentShow={editAssessmentShow}
                        deleteAssessment={deleteAssessment}
                        goToResults={goToResults}
                      />
                    </Col>
                  )}
                </Row>
                {/* {!client?.isGuest && (
                  <Row
                    justify={"center"}
                    align={"center"}
                    style={{
                      position: "relative",
                      marginTop: isRowLayout ? "2rem" : "1rem",
                    }}
                  >
                    <FreeRecordList
                      clientID={client?.id}
                      assessments={assessments}
                      editAssessmentShow={editAssessmentShow}
                      deleteAssessment={deleteAssessment}
                      goToResults={goToResults}
                    />
                  </Row>
                )} */}
              </Col>
            </TabPane>

            {!client?.isGuest && (
              <TabPane tab="Training" key="workouts">
                <Col style={{ bottom: 75 }}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: flexDirection,
                      justifyContent: "center",
                      position: "relative"
                    }}
                  >
                    <Col
                      span={12}
                      style={{
                        right: isRowLayout ? "8vw" : null,
                        position: "relative",
                        animation: "fade-in 10s forwards"
                      }}
                    >
                      <WorkoutSchedule
                        clientID={client?.id}
                        assessments={assessments}
                        editAssessmentShow={editAssessmentShow}
                        deleteAssessment={deleteAssessment}
                        goToResults={goToResults}
                      />
                    </Col>
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative",
                        left: "2vw"
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <Cycles />
                    </Col>
                  </Row>
                  <Row
                    justify={"center"}
                    align={"center"}
                    style={{
                      position: "relative",
                      marginTop: isRowLayout ? "2rem" : "1rem"
                    }}
                  >
                    {/* <FreeRecordList
                    clientID={client?.id}
                    assessments={assessments}
                    editAssessmentShow={editAssessmentShow}
                    deleteAssessment={deleteAssessment}
                    goToResults={goToResults}
                  /> */}
                  </Row>
                </Col>
              </TabPane>
            )}
            {!client?.isGuest && (
              <TabPane tab="Profile" key="profile">
                {client?.profile && client?.type ? (
                  <ClientProfile />
                ) : (
                  <Col
                    align="middle"
                    justify="center"
                    style={{ width: "78vw" }}
                  >
                    <Card
                      align="middle"
                      justify="center"
                      style={{
                        position: "relative",
                        bottom: "7vh",
                        minHeight: "25vh",
                        background: Theme.CARD,
                        borderWidth: 0
                      }}
                    >
                      <Col
                        align="middle"
                        justify="center"
                        style={{ marginTop: "2vw" }}
                      >
                        <Text style={{ color: Theme.TEXT }}>
                          Choose Client Type to View Profile
                        </Text>
                        <Col style={{ marginTop: "1vw" }}>
                          <CustomSelect
                            // defaultValue="Athlete"
                            options={[
                              { value: "athlete", label: "Athlete" },
                              { value: "fitness", label: "Fitness" },
                              { value: "other", label: "Other" }
                            ]}
                            onChange={(value) => {
                              setType(value);
                            }}
                            width="10vw"
                            placeholder="Select an option"
                            lightModeBackground="#f1f1fb"
                            darkModeBackground="#000"
                          />
                        </Col>
                        <Button
                          textStyle={{ fontSize: "3vw" }}
                          disabled={type === ""}
                          style={{
                            borderWidth: 0,
                            padding: 3,
                            width: "5vw",
                            borderRadius: 5,

                            color: "white",
                            fontSize: ".65vw",
                            fontWeight: "bold",

                            backgroundImage:
                              type === ""
                                ? "linear-gradient(45deg, #DCDCDC 0%, #DCDCDC 80%)"
                                : "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                            height: "1.3vw",
                            cursor: "pointer",
                            marginLeft: ".5vw",
                            zIndex: 999,
                            marginTop: "1vw"
                          }}
                          onClick={() => {
                            const storedId = client?.id;
                            dispatch(patchClient(client?.id, { type: type }))
                              .then(() => {
                                // Add a 500ms delay before fetching updated data
                                setTimeout(() => {
                                  dispatch(getSingleClient(storedId));
                                }, 1000);
                              })
                              .catch((error) => {
                                console.error("Error updating client:", error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      </Col>
                    </Card>
                  </Col>
                )}
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Col>
    </Row>
  );
};

export default ClientDetails;
