// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const RESET_PASS_FAIL = "RESET_PASS_FAIL";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const FACEBOOK_AUTH_SUCCESS = "FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "FACEBOOK_AUTH_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_AUTH_STATUS = "RESET_AUTH_STATUS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

// Payment
export const CHECK_SUBSCRIPTION = "CHECK_SUBSCRIPTION";
export const RESET_CHECK = "RESET_CHECK";

// Clients
export const GET_CLIENTS = "GET_CLIENTS";
export const SEARCH_CLIENTS = "SEARCH_CLIENTS";
export const GET_SINGLE_CLIENT = "GET_SINGLE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_PROFILE = "UPDATE_CLIENT_PROFILE";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";
export const RESET_CLIENTS_STATUS = "RESET_CLIENTS_STATUS";
export const GET_PROGRESS = "GET_PROGRESS";
export const RESET_CLIENT = "RESET_CLIENT";
export const CREATE_CLIENT_ACCOUNT_SUCCESS = "CREATE_CLIENT_ACCOUNT_SUCCESS";
export const CREATE_CLIENT_ACCOUNT_FAIL = "CREATE_CLIENT_ACCOUNT_FAIL";
export const RESET_CLIENT_ACCOUNT = "RESET_CLIENT_ACCOUNT";

// Trend Report - part of clients for now
export const GET_TREND_REPORT = "GET_TREND_REPORT";
export const GET_TREND_REPORT_JC = "GET_TREND_REPORT_JC";
export const GET_PROGRESS_JC = "GET_PROGRESS_JC";

// Trainers/Org
export const GET_TRAINERS = "GET_TRAINERS";
export const SEARCH_TRAINERS = "SEARCH_TRAINERS";
export const GET_SINGLE_TRAINER = "GET_SINGLE_TRAINER";
export const UPDATE_TRAINER = "UPDATE_TRAINER";
export const DELETE_TRAINER = "DELETE_TRAINER";
export const CREATE_TRAINER_SUCCESS = "CREATE_TRAINER_SUCCESS";
export const CREATE_TRAINER_FAIL = "CREATE_TRAINER_FAIL";
export const RESET_TRAINER_STATUS = "RESET_TRAINER_STATUS";
export const UPDATE_TRAINER_PROFILE = "UPDATE_TRAINER_PROFILE";
export const GET_SINGLE_TRAINER_FAILED = "GET_SINGLE_TRAINER_FAILED";
export const RESET_TRAINER = "RESET_TRAINER";

// Groups
export const GET_GROUPS = "GET_GROUPS";
export const GET_SINGLE_GROUP = "GET_SINGLE_GROUP";
export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";
export const RESET_GROUP_STATUS = "RESET_GROUP_STATUS";
export const RESET_GROUP = "RESET_GROUP";

// Trainer Groups
export const GET_TRAINER_GROUPS = "GET_TRAINER_GROUPS";
export const GET_SINGLE_TRAINER_GROUP = "GET_SINGLE_TRAINER_GROUP";
export const SEARCH_TRAINER_GROUPS = "SEARCH_TRAINER_GROUPS";
export const UPDATE_TRAINER_GROUP = "UPDATE_TRAINER_GROUP";
export const DELETE_TRAINER_GROUP = "DELETE_TRAINER_GROUP";
export const CREATE_TRAINER_GROUP_SUCCESS = "CREATE_TRAINER_GROUP_SUCCESS";
export const CREATE_TRAINER_GROUP_FAIL = "CREATE_TRAINER_GROUP_FAIL";
export const RESET_TRAINER_GROUP_STATUS = "RESET_TRAINER_GROUP_STATUS";
export const TRAINER_GROUP_MOVEMENTS = "TRAINER_GROUP_MOVEMENTS";

// Organization
export const GET_ORG = "GET_ORG";
export const UPDATE_ORG = "UPDATE_ORG";
export const RESET_ORG_STATUS = "RESET_ORG_STATUS";

// Assessments
export const GET_ASSESSMENTS = "GET_ASSESSMENTS";
export const GET_FREE_RECORDS = "GET_FREE_RECORDS";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_FAIL = "CREATE_ASSESSMENT_FAIL";
export const GET_SINGLE_ASSESSMENT = "GET_SINGLE_ASSESSMENT";
export const RESET_ASSESSMENT = "RESET_ASSESSMENT";
export const RESET_ASSESSMENT_STATUS = "RESET_ASSESSMENT_STATUS";
export const CREATE_SQUAT_RESULT = "CREATE_SQUAT_RESULT";
export const CREATE_POSTURE_RESULT = "CREATE_POSTURE_RESULT";
export const CREATE_PUSH_RESULT = "CREATE_PUSH_RESULT";
export const CREATE_RIGHT_BALANCE_RESULT = "CREATE_RIGHT_BALANCE_RESULT";
export const CREATE_LEFT_BALANCE_RESULT = "CREATE_LEFT_BALANCE_RESULT";
export const CREATE_RIGHT_LUNGE_RESULT = "CREATE_RIGHT_LUNGE_RESULT";
export const CREATE_LEFT_LUNGE_RESULT = "CREATE_LEFT_LUNGE_RESULT";
export const CREATE_RIGHT_RDL_RESULT = "CREATE_RIGHT_RDL_RESULT";
export const CREATE_LEFT_RDL_RESULT = "CREATE_LEFT_RDL_RESULT";
export const CREATE_FREE_RESULT = "CREATE_FREE_RESULT";
export const RESET_MOVEMENTS = "RESET_MOVEMENTS";
export const RESET_FREE_UPDATED = "RESET_FREE_UPDATED";
export const FAIL_SQUAT_RESULT = "FAIL_SQUAT_RESULT";
export const FAIL_POSTURE_RESULT = "FAIL_POSTURE_RESULT";
export const FAIL_PUSH_RESULT = "FAIL_PUSH_RESULT";
export const FAIL_RIGHT_BALANCE_RESULT = "FAIL_RIGHT_BALANCE_RESULT";
export const FAIL_LEFT_BALANCE_RESULT = "FAIL_LEFT_BALANCE_RESULT";
export const FAIL_RIGHT_LUNGE_RESULT = "FAIL_RIGHT_LUNGE_RESULT";
export const FAIL_LEFT_LUNGE_RESULT = "FAIL_LEFT_LUNGE_RESULT";
export const FAIL_RIGHT_RDL_RESULT = "FAIL_RIGHT_RDL_RESULT";
export const FAIL_LEFT_RDL_RESULT = "FAIL_LEFT_RDL_RESULT";
export const FAIL_FREE_RESULT = "FAIL_FREE_RESULT";
export const RESET_SQUAT_STATUS = "RESET_SQUAT_STATUS";
export const RESET_POSTURE_STATUS = "RESET_POSTURE_STATUS";
export const RESET_PUSH_STATUS = "RESET_PUSH_STATUS";
export const RESET_BALANCE_STATUS = "RESET_BALANCE_STATUS";
export const RESET_LUNGE_STATUS = "RESET_LUNGE_STATUS";
export const RESET_RDL_STATUS = "RESET_RDL_STATUS";
export const RESET_FREE_STATUS = "RESET_FREE_STATUS";
export const RESET_COUNTER = "RESET_COUNTER";
export const PATCH_ASSESSMENT_SUCCESS = "PATCH_ASSESSMENT_SUCCESS";

// Joint Comparisons
export const GET_JOINT_COMPARISONS = "GET_JOINT_COMPARISONS";
export const CREATE_JOINT_COMPARISON_SUCCESS =
  "CREATE_JOINT_COMPARISON_SUCCESS";
export const CREATE_JOINT_COMPARISON_FAIL = "CREATE_JOINT_COMPARISON_FAIL";
export const GET_SINGLE_JOINT_COMPARISON = "GET_SINGLE_JOINT_COMPARISON";
export const RESET_JOINT_COMPARISON = "RESET_JOINT_COMPARISON";
export const RESET_JOINT_COMPARISON_STATUS = "RESET_JOINT_COMPARISON_STATUS";
export const PATCH_JOINT_COMPARISON_SUCCESS = "PATCH_JOINT_COMPARISON_SUCCESS";
export const RESET_JOINT_COUNTER = "RESET_JOINT_COUNTER";

// Blogs
export const GET_BLOGS = "GET_BLOGS";
export const SEARCH_BLOGS = "SEARCH_BLOGS";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SUPPORT = "GET_SUPPORT";
export const SEARCH_SUPPORT = "SEARCH_SUPPORT";
export const GET_SINGLE_SUPPORTS = "GET_SINGLE_SUPPORTS";

// Workouts
export const CREATE_WORKOUT_SUCCESS = "CREATE_WORKOUT_SUCCESS";
export const CREATE_WORKOUT_FAIL = "CREATE_WORKOUT_FAIL";
export const GET_WORKOUTS = "GET_WORKOUTS";
export const GET_SINGLE_WORKOUT = "GET_SINGLE_WORKOUT";
export const UPDATE_WORKOUT = "UPDATE_WORKOUT";
export const DELETE_WORKOUT = "DELETE_WORKOUT";
export const RESET_WORKOUT_STATUS = "RESET_WORKOUT_STATUS";
export const COPY_WORKOUT_SUCCESS = "COPY_WORKOUT_SUCCESS";

// Exercises
export const GET_EXERCISES = "GET_EXERCISES";
export const CREATE_EXERCISE_SUCCESS = "CREATE_EXERCISE_SUCCESS";
export const CREATE_EXERCISE_FAIL = "CREATE_EXERCISE_FAIL";
export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const DELETE_EXERCISE = "DELETE_EXERCISE";
export const RESET_EXERCISE_STATUS = "RESET_EXERCISE_STATUS";
export const CREATE_LIBRARY_EXERCISE_SUCCESS =
  "CREATE_LIBRARY_EXERCISE_SUCCESS";
export const CREATE_LIBRARY_EXERCISE_FAIL = "CREATE_LIBRARY_EXERCISE_FAIL";

// Cycles
export const CREATE_MESOCYCLE_SUCCESS = "CREATE_MESOCYCLE_SUCCESS";
export const CREATE_MESOCYCLE_FAIL = "CREATE_MESOCYCLE_FAIL";
export const GET_MEOSCYCLES = "GET_MEOSCYCLES";
export const GET_SINGLE_MESOCYCLE = "GET_SINGLE_MESOCYCLE";
export const UPDATE_MESOCYCLE = "UPDATE_MESOCYCLE";
export const DELETE_MESOCYCLE = "DELETE_MESOCYCLE";
export const RESET_MESOCYCLE_STATUS = "RESET_MESOCYCLE_STATUS";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_FAIL = "CREATE_PROGRAM_FAIL";
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_SINGLE_PROGRAM = "GET_SINGLE_PROGRAM";
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const RESET_PROGRAM_STATUS = "RESET_PROGRAM_STATUS";
export const COPY_WEEK_SUCCESS = "COPY_WEEK_SUCCESS";
export const COPY_WEEK_FAIL = "COPY_WEEK_FAIL";

// Templates
export const CREATE_WORKOUT_TEMPLATE_SUCCESS =
  "CREATE_WORKOUT_TEMPLATE_SUCCESS";
export const CREATE_WORKOUT_TEMPLATE_FAIL = "CREATE_WORKOUT_TEMPLATE_FAIL";
export const GET_WORKOUT_TEMPLATES = "GET_WORKOUT_TEMPLATES";
export const GET_SINGLE_WORKOUT_TEMPLATE = "GET_SINGLE_WORKOUT_TEMPLATE";
export const UPDATE_WORKOUT_TEMPLATE = "UPDATE_WORKOUT_TEMPLATE";
export const DELETE_WORKOUT_TEMPLATE = "DELETE_WORKOUT_TEMPLATE";
export const RESET_WORKOUT_TEMPLATE_STATUS = "RESET_WORKOUT_TEMPLATE_STATUS";
export const COPY_WORKOUT_TEMPLATE_SUCCESS = "COPY_WORKOUT_TEMPLATE_SUCCESS";
export const ASSIGN_WORKOUT_TEMPLATE_SUCCESS =
  "ASSIGN_WORKOUT_TEMPLATE_SUCCESS";

export const CREATE_BLOCK_TEMPLATE_SUCCESS = "CREATE_BLOCK_TEMPLATE_SUCCESS";
export const CREATE_BLOCK_TEMPLATE_FAIL = "CREATE_BLOCK_TEMPLATE_FAIL";
export const GET_BLOCK_TEMPLATES = "GET_BLOCK_TEMPLATES";
export const GET_SINGLE_BLOCK_TEMPLATE = "GET_SINGLE_BLOCK_TEMPLATE";
export const UPDATE_BLOCK_TEMPLATE = "UPDATE_BLOCK_TEMPLATE";
export const DELETE_BLOCK_TEMPLATE = "DELETE_BLOCK_TEMPLATE";
export const RESET_BLOCK_TEMPLATE_STATUS = "RESET_BLOCK_TEMPLATE_STATUS";
export const COPY_BLOCK_TEMPLATE_SUCCESS = "COPY_BLOCK_TEMPLATE_SUCCESS";
export const ASSIGN_BLOCK_TEMPLATE_SUCCESS = "ASSIGN_BLOCK_TEMPLATE_SUCCESS";
export const COPY_TEMPLATE_WEEK_SUCCESS = "COPY_TEMPLATE_WEEK_SUCCESS";
export const COPY_TEMPLATE_WEEK_FAIL = "COPY_TEMPLATE_WEEK_FAIL";

export const CREATE_PROGRAM_TEMPLATE_SUCCESS =
  "CREATE_PROGRAM_TEMPLATE_SUCCESS";
export const CREATE_PROGRAM_TEMPLATE_FAIL = "CREATE_PROGRAM_TEMPLATE_FAIL";
export const GET_PROGRAM_TEMPLATES = "GET_PROGRAM_TEMPLATES";
export const GET_SINGLE_PROGRAM_TEMPLATE = "GET_SINGLE_PROGRAM_TEMPLATE";
export const UPDATE_PROGRAM_TEMPLATE = "UPDATE_PROGRAM_TEMPLATE";
export const DELETE_PROGRAM_TEMPLATE = "DELETE_PROGRAM_TEMPLATE";
export const RESET_PROGRAM_TEMPLATE_STATUS = "RESET_PROGRAM_TEMPLATE_STATUS";
export const COPY_PROGRAM_TEMPLATE_SUCCESS = "COPY_PROGRAM_TEMPLATE_SUCCESS";
export const ASSIGN_PROGRAM_TEMPLATE_SUCCESS =
  "ASSIGN_PROGRAM_TEMPLATE_SUCCESS";

// Filters
export const SET_QUERY_FILTER = "SET_QUERY_FILTER";
export const SET_GROUP_FILTER = "SET_GROUP_FILTER";
export const SET_TRAINER_GROUP_FILTER = "SET_TRAINER_GROUP_FILTER";
export const SET_TRAINER_FILTER = "SET_TRAINER_FILTER";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const RESET_FILTERS = "RESET_FILTERS";

// Profiles
export const GET_RANGE_PROFILES = "GET_RANGE_PROFILES";
export const GET_RANGE_PROFILE = "GET_RANGE_PROFILE";
export const CREATE_RANGE_PROFILE_SUCCESS = "CREATE_RANGE_PROFILE_SUCCESS";
export const CREATE_RANGE_PROFILE_FAIL = "CREATE_RANGE_PROFILE_FAIL";
export const SEARCH_RANGE_PROFILES = "SEARCH_RANGE_PROFILES";
export const UPDATE_RANGE_PROFILE = "UPDATE_RANGE_PROFILE";
export const DELETE_RANGE_PROFILE = "DELETE_RANGE_PROFILE";
export const RESET_RANGE_PROFILE_STATUS = "RESET_RANGE_PROFILE_STATUS";

// PDF
export const SET_THEME = "SET_THEME";
