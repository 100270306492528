import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useSelector } from "react-redux";
import ProgressProvider from "./ProgressProvider";

const ScoreCircle = ({ score, height }) => {
  const { Theme, user } = useSelector((state) => state.auth);
  const greenScore = user?.profile?.scoring_colors?.green || 80;
  const yellowScore = user?.profile?.scoring_colors?.yellow || 65;
  return (
    <ProgressProvider valueStart={0} valueEnd={score}>
      {(value) => (
        <CircularProgressbar
          styles={{
            root: { height: height, marginBottom: ".5vw" },
            path: {
              stroke:
                score >= greenScore
                  ? Theme.GRAD6
                  : score >= yellowScore
                  ? Theme.YELLOW
                  : Theme.RED,
              strokeLinecap: "butt",
              transition: "stroke-dashoffset 0.5s ease 0s",
              transformOrigin: "center center"
            },
            trail: {
              stroke: Theme.INPUT,
              strokeLinecap: "butt",
              transformOrigin: "center center"
            },
            text: {
              fill: Theme.TEXT,
              fontSize: "1.5vw"
            },
            background: {
              fill: "#3e98c7"
            }
          }}
          value={value}
          text={score}
        />
      )}
    </ProgressProvider>
  );
};

// Export the component
export default ScoreCircle;
