import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleTrainerGroup,
  getTrainerGroupMovements,
  massAssignRanges,
  updateTrainerGroups
} from "../redux/actions/trainer_groups";
import clientpic from "../assets/images/profilepic.jpg";
import {
  Card,
  Col,
  Modal,
  Input,
  Row,
  Typography,
  Menu,
  Dropdown,
  Spin,
  Select
} from "antd";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import AssignAssessments from "./AssignAssesments";
import { Button } from "react-bootstrap";
import {
  IoAddCircleOutline,
  IoChevronBackOutline,
  IoInformationCircleOutline
} from "react-icons/io5";
import AddClientGroupForm from "./results/AddClientGroupForm";
import { getProfiles, getSingleClient } from "../redux/actions/clients";
import BootstrapSearch from "./common/Search";
import CustomSelect from "./common/DropDown";
import StyledDatePickerComponent from "./common/DatePicker";
import { css, StyleSheet } from "aphrodite";
import Theme from "../constants/Theme";
import WorkoutTemplateModal from "./templates/WorkoutTemplateModal";
import BlockTemplateModal from "./templates/BlockTemplateModal";
import ProgramTemplateModal from "./templates/ProgramTemplateModal";
import ScoreCircle from "./chart/ScoreCircle";

const { Text } = Typography;

const TrainerGroupList = ({ group_type }) => {
  const dispatch = useDispatch();
  const { user, Theme } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const [assigned, setAssigned] = useState(false);
  const { trainer_group, trainerGroupMovements } = useSelector(
    (state) => state.trainer_groups
  );
  const { profiles } = useSelector((state) => state.clients);

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const [addTrainerModalVisible, setAddTrainerModalVisible] = useState(false);
  const [massAssignWorkoutModalVisble, setMassAssignWorkoutModalVisible] =
    useState(false);
  const [massAssignBlockModalVisble, setMassAssignBlockModalVisible] =
    useState(false);
  const [massAssignProgramModalVisble, setMassAssignProgramModalVisible] =
    useState(false);
  const [massAssignModalVisible, setMassAssignrModalVisible] = useState(false);

  const [massEditRangeModalVisible, setMassEditRangeModalVisible] =
    useState(false);

  const [chooseAssignModalVisible, setChooseAssignModalVisible] =
    useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [filteredClients, setFilteredClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [selectedStartDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedScoreType, setSelectedScoreType] = useState(
    "last_assessment_score"
  );
  const [loading, setLoading] = useState(true);
  const [selectedRangeTemplate, setSelectedRangeTemplate] = useState(null);

  const formatDate = (date) => {
    return new Date(date).toISOString();
  };

  const profileOptions = profiles.map((profile) => ({
    value: profile.id,
    label: profile.name
  }));

  useEffect(() => {
    if (trainer_group == null) {
      dispatch(getSingleTrainerGroup(id));
    }
    if (trainer_group?.id != null) {
      setAllClients(trainer_group.clients);
      setFilteredClients(trainer_group.clients);
      setLoading(false);
    }
    if (trainer_group.clients) {
      const clientIds = trainer_group.clients.map((client) => client.id);
      const data = {
        clients: clientIds,
        query: selectedScoreType,
        begindate: selectedStartDate ? formatDate(selectedStartDate) : null,
        enddate: selectedEndDate ? formatDate(selectedEndDate) : null
      };
      dispatch(getTrainerGroupMovements(data));
    }
  }, [trainer_group]);

  const filterAsses = () => {
    if (trainer_group.clients) {
      const clientIds = trainer_group.clients.map((client) => client.id);

      const data = {
        clients: clientIds,
        query: selectedScoreType,
        begindate: selectedStartDate ? formatDate(selectedStartDate) : null,
        enddate: selectedEndDate ? formatDate(selectedEndDate) : null
      };
      dispatch(getTrainerGroupMovements(data));
    }
  };

  const handleClick = (id, trainer) => {
    dispatch(getSingleClient(id));
    return history.push(`/client/${id}`);
  };

  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  const history = useHistory();

  useEffect(() => {
    // Add event listener for window resize
    dispatch(getProfiles());

    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleScoreTypeChange = (value, label) => {
    setSelectedScoreType(value);
  };

  const handleClientSearch = (text) => {
    const searchText = text.toLowerCase().trim();
    setQuery(text);
    const filtered = allClients.filter((client) =>
      `${client.first_name} ${client.last_name}`
        .toLowerCase()
        .includes(searchText)
    );

    setFilteredClients(filtered);
  };

  const onClick = ({ key }) => {
    var data = {};
    data = {
      remove_clients: [key]
    };
    dispatch(updateTrainerGroups(id, data));
  };

  const handleRangeSubmit = () => {
    const selectedProfile = profiles.find(
      (profile) => profile.id === selectedRangeTemplate
    );
    dispatch(massAssignRanges(id, selectedProfile.range_profile));
    setMassEditRangeModalVisible(false);
    setSelectedRangeTemplate(null);
  };

  const generateTraineritems = (userId) => [
    {
      key: userId,
      label: <a>Remove</a>
    }
  ];

  function getMovementById(id) {
    const movement = trainerGroupMovements.find((m) => m.id === id);
    if (movement) {
      return {
        score: movement.score,
        date: movement.date
      };
    } else {
      return {
        score: null,
        date: null
      };
    }
  }

  const isFadedIn = true;
  const handleGoToAdmin = () => {
    history.push("/", { fromGroups: true });
  };
  return (
    <div
      style={{
        marginTop: "13vw",
        // left: "2vw",
        position: "absolute",
        height: "100%"
      }}
      className={`fade-in-slide-up-element ${
        isFadedIn ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <div
        onClick={handleGoToAdmin}
        style={{ bottom: "10vw", position: "relative", cursor: "pointer" }}
      >
        <IoChevronBackOutline
          style={{
            top: ".1vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT
          }}
        />
        <Text style={{ color: Theme.TEXT }}>Back</Text>
      </div>
      <Card
        justify={"center"}
        align={"middle"}
        style={{
          width: "60vw",
          position: "relative",
          bottom: "7.5vw",
          minHeight: "50vh",
          background: Theme.CARD,
          borderWidth: 0
        }}
      >
        {loading ? (
          <Spin size="large" style={{ marginBottom: "1vw" }} />
        ) : (
          <div>
            <Row
              justify={"center"}
              align={"middle"}
              className="project-ant"
              style={{
                marginBottom: ".75vw",
                width: "55vw",
                borderBottom: ".10px solid",
                paddingBottom: ".75vw",
                borderBottomColor: Theme.TEXT2
              }}
            >
              <BootstrapSearch
                placeholder={`Search ${user?.profile?.client_label}s`}
                onSearch={handleClientSearch}
                onChange={(e) => handleClientSearch(e.target.value)}
                width="10vw"
              />
              <Button
                onClick={() => setChooseAssignModalVisible(true)}
                style={{
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 7,
                  height: "1.5vw",
                  width: "8vw",
                  fontWeight: "bold",
                  fontSize: ".75vw",
                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                Assign
              </Button>

              <Button
                onClick={() => setAddTrainerModalVisible(true)}
                style={{
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 7,
                  height: "1.5vw",
                  width: "8vw",
                  fontWeight: "bold",
                  fontSize: ".75vw",
                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                <IoAddCircleOutline
                  style={{ top: "5%", marginRight: "4%", position: "relative" }}
                />
                Add {user?.profile?.client_label}
              </Button>
            </Row>

            <Row
              justify={"center"}
              align={"middle"}
              style={{ position: "relative", marginBottom: ".5vw" }}
            >
              <IoInformationCircleOutline
                onClick={() => setModalVisible(true)}
                color={Theme.PRIMARY}
                size={".8vw"}
                style={{
                  alignSelf: "center",
                  marginRight: "1%",
                  // top: ".2vw",
                  zIndex: 80,
                  cursor: "pointer",
                  position: "relative"
                }}
              />
              <CustomSelect
                defaultValue="last_assessment_score"
                width="8vw"
                options={[
                  {
                    value: "last_assessment_score",
                    label: "Overall"
                  },
                  { value: "squat", label: "Squat" },
                  {
                    value: "standard_squat",
                    label: "Standard Squat"
                  },
                  { value: "push", label: "Push" },
                  { value: "lunge", label: "Lunge" },
                  { value: "lat_lunge", label: "Lateral Lunge" },
                  { value: "balance", label: "Balance" },
                  { value: "rdl", label: "Single Leg Hinge" },
                  { value: "hinge", label: "Bilateral Hinge" }
                ]}
                onChange={handleScoreTypeChange}
                placeholder="Select an option"
              />

              <Col style={{ marginLeft: "1vw" }}>
                <StyledDatePickerComponent
                  selected={selectedStartDate}
                  onChange={(date) => setSelectedDate(date)}
                  placeholder={"Start Date"}
                  width={"2vw"}
                  onClear={() => setSelectedDate(null)}
                />
              </Col>
              <Col style={{ marginLeft: "1vw" }}>
                <StyledDatePickerComponent
                  selected={selectedEndDate}
                  onChange={(date) => setSelectedEndDate(date)}
                  placeholder={"End Date"}
                  width={"2vw"}
                  onClear={() => setSelectedEndDate(null)}
                />
              </Col>
              <Button
                onClick={() => filterAsses()}
                style={{
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 12,
                  height: "1.5vw",
                  width: "5vw",
                  fontWeight: "bold",
                  fontSize: ".75vw",
                  cursor: "pointer",
                  borderRadius: 5,
                  marginLeft: "1vw",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                Filter
              </Button>
            </Row>
            <div
              style={{ paddingLeft: "4vw" }}
              className="ant-list-box table-responsive"
            >
              <table className="width-100">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: ".85vw",
                        position: "relative",
                        right: "1.25vw",
                        color: Theme.TEXT2
                      }}
                    >
                      {user?.profile?.client_label.toUpperCase()}
                    </th>
                    <th
                      style={{
                        fontSize: ".85vw",
                        position: "relative",
                        right: "1.25vw",
                        color: Theme.TEXT2
                      }}
                    ></th>
                    <th
                      style={{
                        fontSize: ".85vw",
                        color: Theme.TEXT2,
                        right: "3vw",
                        position: "relative"
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClients.map((d, index) => {
                    var trainerPhotoPath = clientpic;
                    if (d.profile_picture != null) {
                      trainerPhotoPath = d.profile_picture;
                    }

                    const scoreData = getMovementById(d.id);

                    const date = scoreData.date
                      ? new Date(scoreData.date)
                      : null;
                    const scoreValue = scoreData.score
                      ? scoreData.score.toFixed(0)
                      : null;
                    return (
                      <tr
                        key={index}
                        style={{
                          right: "1.75vw",
                          position: "relative",
                          cursor: "pointer"
                        }}
                        onClick={() => handleClick(d.id)}
                      >
                        <td
                          style={{
                            fontSize: ".75vw",
                            position: "relative",
                            right: "1vw"
                          }}
                        >
                          <Row style={{ fontSize: "1vw" }}>
                            <img
                              src={trainerPhotoPath}
                              alt=""
                              className="avatar-sm mr-10"
                              style={{
                                height: "3vw", // Adjust the image height using vw units
                                width: "3vw",
                                position: "relative",
                                borderRadius: 100,
                                objectFit: "cover"
                              }}
                            />
                            {""}
                            <p
                              style={{
                                position: "relative",
                                left: "1vw",
                                fontSize: ".85vw",
                                color: Theme.TEXT
                              }}
                            >
                              {" "}
                              {d.first_name} {d.last_name}
                            </p>
                          </Row>
                        </td>

                        <td style={{ fontSize: ".85vw", textAlign: "center" }}>
                          <Col style={{ position: "relative", right: "1vw" }}>
                            {date !== null ? (
                              <Col
                                style={{ position: "relative" }}
                                align="middle"
                              >
                                {scoreValue && (
                                  <ScoreCircle
                                    height={"3vw"}
                                    score={scoreValue}
                                  />
                                )}
                                <Col align="middle" justify="center">
                                  <Text
                                    style={{
                                      fontSize: ".75vw",

                                      color: Theme.TEXT,
                                      textAlign: "center"
                                    }}
                                  >
                                    {date.toLocaleDateString()}
                                  </Text>
                                </Col>
                              </Col>
                            ) : (
                              <Text
                                style={{
                                  fontSize: ".75vw",
                                  color: Theme.TEXT2
                                }}
                              >
                                No Data
                              </Text>
                            )}
                          </Col>
                        </td>

                        <td>
                          <Dropdown
                            menu={{
                              items: generateTraineritems(d.id),
                              onClick
                            }}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              Edit
                              {/* <DownOutlined /> */}
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Modal
              open={chooseAssignModalVisible}
              onCancel={() => setChooseAssignModalVisible(false)}
              footer={null}
              bodyStyle={{ padding: "1vw" }}
              width={"30vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Col align="middle" justify="center">
                <h1
                  className={css(styles.titleText)}
                  style={{ color: Theme.TEXT, marginBottom: "1.5vw" }}
                >
                  Choose Assignment Type
                </h1>
                <Row align={"middle"} justify={"space-evenly"}>
                  <Button
                    onClick={() => setMassAssignrModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 7,
                      height: "1.5vw",
                      width: "8vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",
                      background: "#000"
                    }}
                  >
                    Assessment
                  </Button>
                  <Button
                    onClick={() => setMassAssignWorkoutModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 7,
                      height: "1.5vw",
                      width: "8vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",
                      background: "#000"
                    }}
                  >
                    Workout
                  </Button>
                </Row>
                <Row
                  align={"middle"}
                  justify={"space-evenly"}
                  style={{ marginTop: "1vw" }}
                >
                  <Button
                    onClick={() => setMassAssignBlockModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 7,
                      height: "1.5vw",
                      width: "8vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",
                      background: "#000"
                    }}
                  >
                    Block
                  </Button>
                  <Button
                    onClick={() => setMassAssignProgramModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 7,
                      height: "1.5vw",
                      width: "8vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",
                      background: "#000"
                    }}
                  >
                    Program
                  </Button>
                </Row>
                <Button
                  onClick={() => setMassEditRangeModalVisible(true)}
                  style={{
                    color: "white",
                    borderWidth: 0,
                    borderRadius: 7,
                    height: "1.5vw",
                    width: "8vw",
                    fontWeight: "bold",
                    fontSize: ".75vw",
                    cursor: "pointer",
                    background: "#000",
                    marginTop: "1vw"
                  }}
                >
                  Ranges
                </Button>
              </Col>
            </Modal>
            <Modal
              open={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
              bodyStyle={{ height: "18vw", padding: "1vw" }}
              width={"30vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Row justify="center">
                <Col span={24}>
                  <h1
                    style={{
                      position: "relative",
                      bottom: "1vw",
                      color: Theme.TEXT
                    }}
                  >
                    Last Assessment Scores
                  </h1>
                  <Text
                    style={{
                      fontSize: "1vw",
                      position: "relative",
                      bottom: "1vw",
                      color: Theme.TEXT2
                    }}
                  >
                    Use this dropdown to select scores: 'Overall' shows your
                    {user?.profile?.client_label.toLowerCase()}s' latest overall
                    assessment score, while selecting specific movements like
                    'squat' displays their last completed score in that
                    movement.
                  </Text>
                  <div>
                    <Text
                      style={{
                        fontSize: "1vw",
                        position: "relative",
                        // bottom: "1vw",
                        color: Theme.TEXT2
                      }}
                    >
                      By setting the start and end dates, you can find the
                      latest assessment or movement scores within that time
                      range.
                    </Text>
                  </div>
                </Col>
              </Row>
            </Modal>
            <Modal
              open={addTrainerModalVisible}
              onCancel={() => setAddTrainerModalVisible(false)}
              footer={null}
              width={"30vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Row style={{ padding: "5%" }}>
                <Col span={24}>
                  <AddClientGroupForm
                    group={trainer_group}
                    closeModal={setAddTrainerModalVisible}
                  />
                </Col>
              </Row>
            </Modal>

            <Modal
              open={massAssignModalVisible}
              onCancel={() => setMassAssignrModalVisible(false)}
              width={"32vw"}
              bodyStyle={{ height: "34vw" }}
              footer={[null]}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <AssignAssessments
                setChooseModalVisible={setMassAssignrModalVisible}
                groupID={id}
              />
            </Modal>
            <Modal
              open={massAssignWorkoutModalVisble}
              onCancel={() => setMassAssignWorkoutModalVisible(false)}
              width={"80vw"}
              bodyStyle={{ minHeight: "34vw" }}
              footer={[null]}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <WorkoutTemplateModal
                closeModal={() => setMassAssignWorkoutModalVisible(false)}
                fromTrainerGroup={true}
              />
            </Modal>
            <Modal
              open={massAssignBlockModalVisble}
              onCancel={() => setMassAssignBlockModalVisible(false)}
              width={"60vw"}
              footer={[null]}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <BlockTemplateModal
                closeModal={() => setMassAssignBlockModalVisible(false)}
                fromTrainerGroup={true}
              />
            </Modal>
            <Modal
              open={massAssignProgramModalVisble}
              onCancel={() => setMassAssignProgramModalVisible(false)}
              width={"60vw"}
              footer={[null]}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <ProgramTemplateModal
                closeModal={() => setMassAssignProgramModalVisible(false)}
                fromTrainerGroup={true}
              />
            </Modal>
            <Modal
              open={massEditRangeModalVisible}
              onCancel={() => setMassEditRangeModalVisible(false)}
              width={"32vw"}
              bodyStyle={{ height: "12vw", padding: "1vw" }}
              footer={[null]}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Col align="center" className={css(styles.registerContainer)}>
                <div className={css(styles.block, styles.center)}>
                  <div className={css(styles.title)}>
                    <p
                      className={css(styles.titleText)}
                      style={{ color: Theme.TEXT }}
                    >
                      Select Range Template
                    </p>
                  </div>

                  <Row
                    justify={"center"}
                    className={css(styles.row)}
                    style={{ bottom: 25, marginBottom: ".75vw" }}
                  >
                    <div className={css(styles.column, styles.margin5)}>
                      <CustomSelect
                        style={{ width: "9vw" }}
                        showSearch
                        suffixIcon={null}
                        options={profileOptions}
                        placeholder={
                          <span
                            style={{
                              color:
                                Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                              fontSize: ".7vw",
                              top: ".05vw",
                              position: "relative",
                              fontWeight: "normal"
                            }}
                          >
                            Choose Range Profile
                          </span>
                        }
                        size={"large"}
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                        onChange={(value) => setSelectedRangeTemplate(value)}
                      ></CustomSelect>
                    </div>
                  </Row>
                  <button
                    className={css(styles.createButton)}
                    type="submit"
                    onClick={handleRangeSubmit}
                    disabled={!selectedRangeTemplate}
                    style={{
                      cursor: !selectedRangeTemplate
                        ? "not-allowed"
                        : "pointer",
                      backgroundColor: !selectedRangeTemplate
                        ? "#ccc"
                        : undefined,
                      color: !selectedRangeTemplate ? "#666" : undefined,
                      opacity: !selectedRangeTemplate ? 0.6 : 1
                    }}
                  >
                    <div className={css(styles.buttonGradient)}>
                      <p className={css(styles.buttontext)}>Submit</p>
                    </div>
                  </button>
                </div>
              </Col>
            </Modal>
          </div>
        )}
      </Card>
    </div>
  );
};

export default TrainerGroupList;
const styles = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },
  registerContainer: {
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "2%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw"
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%"
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center"
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center"
  }
});
