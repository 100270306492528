import React, { useState, useEffect } from "react";
import { Layout, Typography, Card, Modal, Col, Select, Spin } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import Theme from "../constants/Theme";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePassword,
  login,
  resetStatus,
  signup
} from "../redux/actions/auth";
import { IoInformationCircleOutline } from "react-icons/io5";
import signuppic from "../assets/images/logowhite.png";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import CustomSelect from "../components/common/DropDown";
import BootstrapInput from "../components/common/Input";

const { Text } = Typography;
const { Header, Footer, Content } = Layout;

const SignUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { emailaddress } = queryString.parse(location.search);
  const { referral } = queryString.parse(location.search);

  const { isAuthenticated, signUpStatus } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [typeModalShow, setTypeModalShow] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("us");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(emailaddress || "");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [account_type, setAccountType] = useState(1);
  const [errors, setErrors] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const gotosignin = () => {
    setModalShow(false);
    history.push("/sign-in");
    dispatch(resetStatus());
  };

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your password.")
      .min(8, "Must be 8 characters or more")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&]+/, "One special character")
      .matches(/\d+/, "One number"),
    re_password: Yup.string()
      .required("Please re-type your password.")
      .oneOf([Yup.ref("password")], "Your passwords do not match.")
  });

  useEffect(() => {
    if (signUpStatus === 201) {
      dispatch(login(email, password));
      if (account_type === 1) {
        history.push("/subscribe");
      } else if (isMobile) {
        window.location.href = "https://demotu.page.link/anamalium";
      } else {
        setModalShow(true);
      }
      setLoading(false);
    }
  }, [signUpStatus]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Prepare the data to validate
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        re_password: rePassword
      };

      // Validate form data against the schema
      await schema.validate(formData, { abortEarly: false }); // `abortEarly: false` ensures all errors are captured

      const referral_link = referral ? referral.toUpperCase() : "";

      // Dispatch signup action if validation passes
      dispatch(
        signup(
          firstName,
          lastName,
          email,
          password,
          rePassword,
          account_type,
          referral_link
        )
      );
    } catch (error) {
      setLoading(false);

      if (error.name === "ValidationError") {
        // Collect validation errors from Yup
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors); // Set form errors for display
      } else {
        console.error("Unexpected error:", error); // Handle other types of errors
      }
    }
  };

  return (
    <Col
      justify={"center"}
      align={"middle"}
      style={{
        height: "100vh",
        width: "100vw",
        background: "black",
        marginTop: isMobile ? "15%" : null
      }}
    >
      <Col
        justify={"center"}
        align={"middle"}
        style={{ background: "transparent" }}
        className="sign-up-header"
      >
        <Card
          className="card-signup header-solid h-full ant-card pt-0"
          style={{
            top: "38vh",
            minHeight: "37.5vw",
            width: isMobile ? "100%" : "25vw",
            boxShadow: "2px 2px 6px rgba(255, 255, 255, 0.15)",
            background: isMobile
              ? "linear-gradient(45deg, #000 0%, #353939 100%)"
              : "black",
            backgroundImage: isMobile
              ? "black"
              : "linear-gradient(45deg, #000 0%, #353939 100%)",
            position: "relative",
            right: isMobile ? "1.5%" : null
          }}
          bordered={false}
          justify={"center"}
          align={"middle"}
        >
          <img
            className="emwhite"
            style={{ width: isMobile ? "15vw" : "5vw", position: "relative" }}
            src={signuppic}
            alt="signup"
          />
          <Col justify={"center"} align={"middle"}>
            <h1
              style={{
                color: Theme.COLORS.WHITE,
                fontSize: isMobile ? "4.5vw" : "1vw"
              }}
            >
              Create Account
            </h1>

            <Col justify={"center"} align={"middle"} style={{ width: "75%" }}>
              <div>
                <BootstrapInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  style={{
                    marginTop: "2.5%",
                    borderRadius: 10,
                    backgroundColor: "#efefef",
                    width: "100%",
                    position: "relative"
                  }}
                />
                {errors.first_name && (
                  <p style={{ color: "red" }}>{errors.first_name}</p>
                )}
              </div>
              <div>
                <BootstrapInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  style={{
                    marginTop: "5%",
                    borderRadius: 10,
                    backgroundColor: "#efefef",
                    width: "100%",
                    position: "relative"
                  }}
                />
                {errors.last_name && (
                  <p style={{ color: "red" }}>{errors.last_name}</p>
                )}
              </div>
              <div>
                <BootstrapInput
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  style={{
                    marginTop: "5%",
                    borderRadius: 10,
                    backgroundColor: "#efefef",
                    width: "100%",
                    position: "relative"
                  }}
                />
                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              </div>
              <div>
                <BootstrapInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  style={{
                    marginTop: "5%",
                    borderRadius: 10,
                    backgroundColor: "#efefef",
                    width: "100%",
                    position: "relative"
                  }}
                />
                {errors.password && (
                  <p style={{ color: "red" }}>{errors.password}</p>
                )}
              </div>
              <div>
                <BootstrapInput
                  type="password"
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  placeholder="Re-Type Your Password"
                  style={{
                    marginTop: "5%",
                    borderRadius: 10,
                    backgroundColor: "#000",
                    width: "100%",
                    position: "relative"
                  }}
                />
                {errors.re_password && (
                  <p style={{ color: "red" }}>{errors.re_password}</p>
                )}
              </div>
              <div style={{ marginTop: "5%" }}>
                <h3
                  style={{
                    color: Theme.COLORS.WHITE,
                    fontSize: isMobile ? "3.5vw" : ".9vw",
                    marginBottom: isMobile ? "5%" : "2.5%"
                  }}
                >
                  Account Type{" "}
                  <IoInformationCircleOutline
                    onClick={() => setTypeModalShow(true)}
                    color={Theme.COLORS.PRIMARY}
                    size={".8vw"}
                    style={{
                      alignSelf: "center",
                      left: "1%",
                      top: "2%",
                      zIndex: 80,
                      cursor: "pointer",
                      position: "relative"
                    }}
                  />
                </h3>

                <CustomSelect
                  defaultValue="1"
                  options={[
                    { value: "1", label: "Professional" },
                    { value: "0", label: "Personal" }
                  ]}
                  onChange={(value) => setAccountType(value)}
                  width={isMobile ? 250 : "10vw"}
                  placeholder="Select an option"
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                />
              </div>
              <div>
                <div
                  style={{
                    marginTop: isMobile ? "10%" : "1.5vw",
                    width: "100%",
                    position: "relative",
                    fontSize: isMobile ? "3vw" : ".7vw",
                    color: "white"
                  }}
                >
                  <div>
                    By clicking Agree & Join, you agree to Demotu's{" "}
                    <a
                      href="https://www.demotuapp.com/terms"
                      style={{ color: Theme.COLORS.PRIMARY }}
                      className="font-bold"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.demotuapp.com/privacy-policy"
                      style={{ color: Theme.COLORS.PRIMARY }}
                      className="font-bold"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <button
                  type="button"
                  className="sign-up-button"
                  onClick={handleSubmit}
                  style={{
                    marginTop: "10%",
                    borderRadius: 10,
                    backgroundColor: Theme.COLORS.PRIMARY,
                    width: "100%",
                    position: "relative",
                    color: "white",
                    height: 45,
                    border: "none",
                    cursor: "pointer",
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    fontSize: isMobile ? "3vw" : ".9vw"
                  }}
                >
                  {loading ? (
                    <Spin size="small" style={{ color: Theme.COLORS.WHITE }} />
                  ) : (
                    "Agree & Join"
                  )}
                </button>
              </div>
              <div className="sign-in-option">
                <h6
                  style={{
                    color: Theme.COLORS.WHITE,
                    marginTop: "15%",
                    marginBottom: "2.5%"
                  }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={gotosignin}
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      marginLeft: ".25vw",
                      cursor: "pointer"
                    }}
                  >
                    Sign In
                  </span>
                </h6>
              </div>
            </Col>
          </Col>
        </Card>
      </Col>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        footer={null}
        onCancel={() => setTypeModalShow(false)}
        open={typeModalShow}
        style={{ borderRadius: "10%" }}
      >
        <div centered style={{ alignSelf: "center" }}>
          <p
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: "1vw"
            }}
          >
            Account Types
          </p>
          <p
            style={{
              textAlign: "left",

              color: Theme.COLORS.PRIMARY,
              fontSize: ".9vw",
              fontWeight: "bold"
            }}
          >
            Professional
          </p>
          <p
            style={{
              textAlign: "left",

              fontSize: ".85vw"
            }}
          >
            Designed for trainers, clinicians, and other professionals working
            with clients
          </p>
          <p
            style={{
              textAlign: "left",

              color: Theme.COLORS.PRIMARY,
              fontSize: ".9vw",
              fontWeight: "bold"
            }}
          >
            Personal
          </p>
          <p
            style={{
              textAlign: "left",

              fontSize: ".85vw"
            }}
          >
            Intended for individual use to track your own progress and
            performance
          </p>
          <div></div>
        </div>
      </Modal>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        footer={null}
        onCancel={() => setModalShow(false)}
        open={modalShow}
        style={{ borderRadius: "10%" }}
      >
        <div centered style={{ alignSelf: "center" }}>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: 800,
              fontSize: "1vw"
            }}
          >
            Account Created!
          </p>

          <div>
            <Text>
              Personal accounts only have access to the mobile app - Download in
              the App Store or Play Store
            </Text>
          </div>
        </div>
      </Modal>
    </Col>
  );
};

export default SignUp;
