import React from "react";
import { Col, Spin, Typography } from "antd"; // Adjust this import if using something other than React Native
import BootstrapInput from "../common/Input";
import { useSelector } from "react-redux";

const { Text } = Typography;

const RenderProfileSection = ({
  section,
  sectionKey,
  editing,
  onUpdateField
}) => {
  const { Theme } = useSelector((state) => state.auth);
  const items = Array.isArray(section) ? section : [];

  return items.map((item, index) => (
    <Col
      key={index}
      style={{ width: "31%", marginTop: ".5vw", marginBottom: "1vw" }}
    >
      <Text
        style={{
          color: Theme.TEXT,
          fontWeight: "bold",
          fontSize: ".75vw"
        }}
      >
        {item.field}
      </Text>
      <Col style={{}}>
        {editing ? (
          <BootstrapInput
            value={item.value || ""}
            onChange={(e) =>
              onUpdateField(`${sectionKey}[${index}].value`, e.target.value)
            }
          />
        ) : (
          <Text style={{ color: Theme.TEXT2, fontSize: ".75vw" }}>
            {item.value || ""}
          </Text>
        )}
      </Col>
    </Col>
  ));
};

export default RenderProfileSection;
