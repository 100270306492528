import React from "react";
import { Button, Card, Col, Modal, Row } from "antd";

import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import BootstrapInput from "../common/Input";
import useProfileFieldManager from "./useProfileFieldManager";
import { PlusCircleOutlined } from "@ant-design/icons";
import RenderProfileSection from "./RenderProfileSection";

const InjuryTesting = ({
  editing,
  onUpdateField,
  profile,
  setProfile,
  handleSave
}) => {
  const { Theme, user } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const {
    isModalVisible,
    setIsModalVisible,
    newFieldName,
    setNewFieldName,
    newFieldValue,
    setNewFieldValue,
    handleAddField,
    handleSaveField
  } = useProfileFieldManager(profile, setProfile, handleSave);
  return (
    <Row style={{ width: "79vw" }}>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[
          <Button type="primary" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              handleSaveField();
            }}
          >
            Save
          </Button>
        ]}
      >
        <Text
          style={{
            color: Theme.TEXT,
            paddingTop: "1vw",
            marginBottom: "1vw",
            fontSize: ".9vw"
          }}
        >
          Add New Field
        </Text>
        <BootstrapInput
          placeholder="Field Name"
          value={newFieldName}
          onChange={(e) => setNewFieldName(e.target.value)}
          style={{ marginBottom: "1vw", marginTop: "1vw" }}
        />
        <BootstrapInput
          placeholder="Initial Value (Optional)"
          value={newFieldValue}
          onChange={(e) => setNewFieldValue(e.target.value)}
        />
      </Modal>
      <Card
        style={{
          bottom: 75,
          width: "48%",
          marginRight: "1vw",
          // minHeight: "100vh",
          zIndex: 100,
          position: "relative",
          background: Theme.CARD,
          borderWidth: 0,
          right: "8.75vw"
        }}
      >
        <Col align={"start"}>
          <Text
            style={{
              fontSize: "1vw",
              color: Theme.TEXT,
              alignSelf: "flex-start",
              fontWeight: 800
            }}
          >
            Injuries and Medical History
            <PlusCircleOutlined
              style={{
                marginLeft: ".5vw",
                fontSize: "1vw",
                color: Theme.BLUE,
                cursor: "pointer"
              }}
              onClick={() => handleAddField("injury_and_history")}
            />
          </Text>
        </Col>
        <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
          <RenderProfileSection
            section={profile?.injury_and_history || []}
            sectionKey="injury_and_history"
            editing={editing}
            onUpdateField={onUpdateField}
          />
        </Row>
      </Card>
      <Card
        style={{
          bottom: 75,
          width: "48%",
          // minHeight: "100vh",
          zIndex: 100,
          position: "relative",
          background: Theme.CARD,
          borderWidth: 0,
          right: "8.75vw"
        }}
      >
        {" "}
        <Col align={"start"}>
          <Text
            style={{
              fontSize: "1vw",
              color: Theme.TEXT,
              alignSelf: "flex-start",
              fontWeight: 800
            }}
          >
            Testing
            <PlusCircleOutlined
              style={{
                marginLeft: ".5vw",
                fontSize: "1vw",
                color: Theme.BLUE,
                cursor: "pointer"
              }}
              onClick={() => handleAddField("testing")}
            />
          </Text>
          <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
            <RenderProfileSection
              section={profile?.testing || []}
              sectionKey="testing"
              editing={editing}
              onUpdateField={onUpdateField}
            />
          </Row>
        </Col>
      </Card>
    </Row>
  );
};

export default InjuryTesting;
