import React, { useEffect, useState } from "react";
import { Col, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DetailsGoals from "./DetailsGoals";
import InjuryTesting from "./InjuryTesting";
import PsychNutrition from "./PsychNutrition";
import {
  patchClientProfile,
  resetClientStatus
} from "../../redux/actions/clients";
const { Text } = Typography;

const ClientProfile = ({ item, selected, muted }) => {
  const dispatch = useDispatch();
  const { client, clientStatus } = useSelector((state) => state.clients);

  // Set default state to the client profile
  const [profile, setProfile] = useState(client?.profile || {});
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);

  const onUpdateField = (path, value) => {
    setProfile((prevProfile) => {
      const updatedProfile = { ...prevProfile };
      const keys = path
        .replace(/\[(\d+)]/g, ".$1") // Convert array-like syntax to dot notation
        .split(".");

      let current = updatedProfile;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value; // Update the target field
        } else {
          current[key] = current[key] || (isNaN(keys[index + 1]) ? {} : []); // Handle array vs object
          current = current[key];
        }
      });

      return updatedProfile;
    });
  };

  const handleSave = () => {
    dispatch(
      patchClientProfile({ client_id: client?.id, profile_data: profile })
    );
  };

  useEffect(() => {
    if (clientStatus === 200) {
      setEditing(false);
      dispatch(resetClientStatus());
    } else if (clientStatus !== null && clientStatus > 399) {
      setError(true);
    }
  }, [clientStatus]);

  useEffect(() => {
    setProfile(client?.profile);
  }, [client]);

  return (
    <Col style={{ width: "78vw" }}>
      <DetailsGoals
        profile={profile}
        onUpdateField={onUpdateField}
        setEditing={setEditing}
        editing={editing}
        handleSave={handleSave}
        error={error}
        setProfile={setProfile}
      />
      <Col style={{ marginTop: "1vw" }}>
        <InjuryTesting
          profile={profile}
          onUpdateField={onUpdateField}
          editing={editing}
          handleSave={handleSave}
          setProfile={setProfile}
        />
      </Col>
      <Col style={{ marginTop: "1vw" }}>
        <PsychNutrition
          profile={profile}
          onUpdateField={onUpdateField}
          editing={editing}
          handleSave={handleSave}
          setProfile={setProfile}
        />
      </Col>
    </Col>
  );
};

export default ClientProfile;
