import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateClient,
  deleteClient,
  getClients,
  getSingleClient,
  updatePhoto,
  createClientAccountExisting,
  patchClient,
  getProfiles
} from "../redux/actions/clients";
import {
  fetchTrainers,
  getSingleTrainer,
  getTrainers,
  resetTrainer
} from "../redux/actions/trainers";
import { IoCameraOutline } from "react-icons/io5";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Col,
  Row,
  Select,
  Spin,
  Modal,
  Tooltip,
  Typography
} from "antd";
import { createClient } from "../redux/actions/clients";
import guestPic from "../assets/images/guestpic.png";
import profilePic from "../assets/images/profilepic.jpg";
import { load_user, resetPass, resetPassSuccess } from "../redux/actions/auth";
import Text from "antd/lib/typography/Text";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { IoClose } from "react-icons/io5";
import { IoPencilSharp } from "react-icons/io5";
import CustomSelect from "./common/DropDown";
import { StyleSheet, css } from "aphrodite";
import BootstrapInput from "./common/Input";
import DebounceSelect from "./loaders/DebounceSelect";
import RangeTemplates from "./templates/RangeTemplates";
import range_descriptions from "./templates/range_descriptions";
import Theme from "../constants/Theme";

const { Title } = Typography;

export const ClientHeader = ({ clientID }) => {
  const [formVisible, setFormVisible] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { client, profiles } = useSelector((state) => state.clients);
  const { user, Theme, passSuccess } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);

  const [loading, setLoading] = useState(true);
  const [resetLoading, setResetLoading] = useState(false);
  const [passMessage, setPassMessage] = useState("Reset Password");

  var clientName = `${client?.first_name} ${client?.last_name}`;
  const { trainer, trainers } = useSelector((state) => state.trainers);
  const showRangeModal = () => {
    dispatch(getProfiles());
    setIsRangeModalVisible(true);
  };

  const handleRangeModalCancel = () => {
    setIsRangeModalVisible(false);
  };

  const handleRangeModalReset = () => {
    setResetModalVisible(true);
  };

  const closeResetModal = () => {
    setResetModalVisible(false);
  };
  const updateRanges = () => {
    const updatedData = {
      range_profile: rangeProfile
    };

    dispatch(patchClient(client?.id, updatedData));
    setIsRangeModalVisible(false);
  };

  const showRangeInfoModal = (category) => {
    const descriptionData = range_descriptions[category];
    if (descriptionData) {
      setModalContent({
        name: category,
        description: descriptionData.description
      });
      seRangeInfoModalVisible(true);
    }
  };
  const handleRangeReset = () => {
    const selectedProfile = profiles.find(
      (profile) => profile.id === selectedRangeTemplate
    );
    const updatedData = {
      range_profile: selectedProfile.range_profile
    };
    dispatch(patchClient(client?.id, updatedData));
    setSelectedRangeTemplate(null);
    setResetModalVisible(false);
    //setIsRangeModalVisible(false);
  };

  const fetchClient = () => dispatch(getSingleClient(id));
  useEffect(() => {
    fetchClient();
    // Need trainer count to determine if this is an admin
    if (user.profile?.account_type == 1) {
      dispatch(getTrainers());
    }
  }, []);

  useEffect(() => {
    setResetLoading(false);

    if (passSuccess === true) {
      setPassMessage("Success");
    } else if (passSuccess === false) {
      setPassMessage("Error");
    } else {
      setPassMessage("Reset Password");
    }
    setTimeout(() => {
      dispatch(resetPassSuccess());
    }, 5000);
  }, [passSuccess]);

  const [dob, setDob] = useState("");
  const [first_name, setFirstNameInputValue] = useState("");
  const [last_name, setlastNameInputValue] = useState("");
  const [email, setEmailInputValue] = useState("");
  const [height_feet, setHeightFeetInputValue] = useState(null);
  const [height_inches, setHeightInchesInputValue] = useState(null);
  const [weight, setWeightInputValue] = useState(null);
  const [weight_kg, setWeightKgInputValue] = useState(null);
  const [height_cm, setHeightcmInputValue] = useState(null);
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});

  const [patchPhoto, setPatchPhoto] = useState();
  var clientPhotoPath = profilePic;
  const [assigned, setAssigned] = useState(false);
  const dispatch = useDispatch();
  const [clientPhoto, setClientPhoto] = useState(clientPhotoPath);
  const [selectedTrainer, setSelectedTrainer] = useState(client?.trainer);
  const [rangeProfile, setRangeProfile] = useState({});
  const [isRangeModalVisible, setIsRangeModalVisible] = useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [selectedRangeTemplate, setSelectedRangeTemplate] = useState(null);
  const [isRangeInfoModalVisible, seRangeInfoModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    name: "",
    description: ""
  });

  const profileOptions = profiles.map((profile) => ({
    value: profile.id,
    label: profile.name
  }));

  const calculateAge = (dob) => {
    const [year, month, day] = dob.split("-");
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const clientAge = client?.dob ? calculateAge(client?.dob) : null;

  const convertToISODate = (date) => {
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleSubmit = (values, actions) => {
    doSubmit(values, actions);
  };

  const doSubmit = async (values, actions) => {
    setErrors({});
    const newErrors = {};

    if (!first_name) {
      newErrors.first_name = "Required";
    }
    if (!last_name) {
      newErrors.last_name = "Required";
    }
    if (!email && client?.user_account) {
      newErrors.email = "Required";
    } else if (
      email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      newErrors.email = "Invalid email address";
    }
    if (!dob) {
      newErrors.dob = "Required";
    } else if (
      !/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(dob)
    ) {
      newErrors.dob = "MM/DD/YYYY";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      var birthDay = convertToISODate(dob);
      dispatch(
        updateClient(
          clientID,
          first_name,
          last_name,
          email,
          birthDay,
          weight || null,
          height_feet || null,
          height_inches || null,
          weight_kg || null,
          height_cm || null,
          gender,
          selectedTrainer
        )
      );
      if (patchPhoto) {
        dispatch(updatePhoto(clientID, patchPhoto, 0));
      }
      setTimeout(() => {
        dispatch(getSingleClient(clientID));
        setFormVisible(false);
      }, 1500);
    } catch (error) {
      setErrors({ submit: "An error occurred while submitting the form" });
    }
  };

  useEffect(() => {
    if (client != null && client?.id != null) {
      if (
        client?.trainer != null &&
        (user?.profile.account_type == 2 || trainers.length > 1)
      ) {
        dispatch(getSingleTrainer(client?.trainer));
      } else {
        dispatch(resetTrainer());
      }
      setLoading(false);
    }

    if (client?.profile_picture != null) {
      setClientPhoto(client?.profile_picture);
    } else if (client?.isGuest) {
      setClientPhoto(guestPic);
    } else {
      setClientPhoto(profilePic);
    }
    clientName = `${client?.first_name} ${client?.last_name}`;
    if (client?.hasOwnProperty("assigned_assessment")) {
      if (client?.assigned_assessment.length > 0) {
        setAssigned(true);
      } else {
        setAssigned(false);
      }
    }
    setSelectedTrainer(client?.trainer);
    setRangeProfile(client?.range_profile);
  }, [client]);

  const deleteThisClient = async () => {
    dispatch(deleteClient(clientID));

    setTimeout(() => {
      dispatch(load_user());
      dispatch(getClients());
      history.push("/");
    }, 500);
  };

  const convertToMMDDYYYY = (date) => {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  };

  const openUpdateForm = async () => {
    if (user?.profile?.account_type === 2) {
      dispatch(getTrainers());
    }
    setFormVisible(!formVisible);
    fetchClient();
    setFirstNameInputValue(client?.first_name);
    setlastNameInputValue(client?.last_name);
    setEmailInputValue(client?.email);
    setDob(client?.dob ? convertToMMDDYYYY(client?.dob) : "");
    setHeightFeetInputValue(client?.height_feet);
    setHeightInchesInputValue(client?.height_inches);
    setWeightInputValue(client?.weight);
    setWeightKgInputValue(client?.weight_kg);
    setHeightcmInputValue(client?.height_cm);
    setGender(client?.gender);
  };

  const deleteAlert = () => {
    const confirmed = window.confirm(
      `Are You Sure You Want to Delete this ${user?.profile?.client_label}?`
    );
    if (confirmed) {
      deleteThisClient();
    }
  };

  const createAlert = () => {
    const confirmed = window.confirm(
      `Are You Sure You Want to Create an Account for this ${user?.profile?.client_label}?`
    );
    if (confirmed) {
      dispatch(
        createClientAccountExisting(
          client?.first_name,
          client?.last_name,
          client?.email
        )
      ).then((response) => fetchClient());
    }
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async () => {
        setClientPhoto(reader.result);
        setPatchPhoto(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleImageClick = () => {
    // Programmatically trigger the file input's click event
    document.getElementById("imageInput").click();
  };
  const formatDateString = (text) => {
    const cleaned = text.replace(/[^0-9]/g, "");
    let formatted = cleaned;

    if (cleaned.length > 2) {
      formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2)}`;
    }
    if (cleaned.length > 4) {
      formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(
        2,
        4
      )}/${cleaned.slice(4)}`;
    }

    return formatted;
  };

  return (
    <Col
      align="middle"
      justify="center"
      style={{
        width: "30vw",
        backgroundColor: Theme.CARD,
        borderWidth: 0,
        maxHeight: formVisible ? null : "10vw",
        marginBottom: 50,
        position: "relative",
        borderRadius: 10,
        paddingTop: "1vw",
        paddingBottom: "1vw"
      }}
      className="criclebox h-full"
    >
      {loading ? (
        <Spin size="large" style={{ marginBottom: "1vw" }} />
      ) : (
        <Col align="middle" justify="center" style={{}}>
          {!formVisible ? (
            <Row align="middle" justify="center">
              <Col align="middle" justify="center">
                <img
                  src={clientPhoto}
                  style={{
                    width: "4.5vw",
                    height: "4.5vw",
                    alignSelf: "center",
                    objectFit: "cover",

                    position: "relative",
                    borderRadius: "100%"
                  }}
                  alt="Client"
                />
              </Col>
              <Col
                align="middle"
                justify="center"
                style={{
                  left: "2vw",
                  // flex: 1,
                  width: "15vw",
                  position: "relative",

                  opacity: 1
                }}
              >
                <Row
                  justify={"start"}
                  style={{
                    flex: 1,

                    overflow: "hidden"
                  }}
                >
                  {clientName && (
                    <Text
                      style={{
                        fontSize: "1.15vw",
                        textAlign: "left",
                        whiteSpace: "wrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "12vw",
                        color: Theme.TEXT
                      }}
                    >
                      {clientName}{" "}
                      <span
                        style={{
                          top: 0,
                          color: Theme.PRIMARY,
                          cursor: "pointer"
                        }}
                        onClick={() => openUpdateForm()}
                      >
                        {!client?.isGuest && <IoPencilSharp size={".89vw"} />}
                      </span>
                    </Text>
                  )}
                </Row>

                {!client?.isGuest && (
                  <div>
                    {" "}
                    <Row>
                      <Text
                        style={{
                          color: Theme.TEXT,
                          fontSize: ".75vw",
                          width: "11vw",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          textAlign: "left"
                        }}
                      >
                        {client?.email}
                      </Text>
                    </Row>
                  </div>
                )}
                {!client?.isGuest && (
                  <Row
                    justify={"left"}
                    align={"start"}
                    style={{
                      textAlign: "left",
                      gap: "1vw"
                    }}
                  >
                    <Col>
                      <Col>
                        <Text
                          style={{
                            fontSize: ".75vw",
                            color: Theme.PRIMARY
                          }}
                        >
                          {clientAge}
                        </Text>
                      </Col>
                    </Col>
                    <Col>
                      {user?.profile?.measurement === "Imperial" ? (
                        <Text
                          style={{
                            fontSize: ".75vw",

                            color: Theme.PRIMARY
                          }}
                        >
                          {client?.weight}lbs
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: ".75vw",

                            color: Theme.PRIMARY
                          }}
                        >
                          {client?.weight_kg}kg
                        </Text>
                      )}
                    </Col>
                    <Col>
                      {user?.profile?.measurement === "Imperial" ? (
                        <Text
                          style={{ fontSize: ".75vw", color: Theme.PRIMARY }}
                        >
                          {client?.height_feet}'{client?.height_inches}"
                        </Text>
                      ) : (
                        <Text
                          style={{ fontSize: ".75vw", color: Theme.PRIMARY }}
                        >
                          {client?.height_cm}cm
                        </Text>
                      )}
                    </Col>
                    <Col>
                      {client?.gender === "M" && (
                        <Text
                          style={{ fontSize: ".75vw", color: Theme.PRIMARY }}
                        >
                          Male
                        </Text>
                      )}{" "}
                      {client?.gender === "F" && (
                        <Text
                          style={{ fontSize: ".75vw", color: Theme.PRIMARY }}
                        >
                          Female
                        </Text>
                      )}
                    </Col>
                  </Row>
                )}
                {(user?.profile.account_type === 2 || trainers.length > 1) && (
                  <Row>
                    <Text
                      style={{
                        fontSize: ".75vw",
                        marginRight: ".5vw",
                        color: Theme.TEXT
                      }}
                    >
                      {" "}
                      Trainer:{""}
                    </Text>
                    <Text
                      style={{
                        fontSize: ".75vw",
                        color: Theme.PRIMARY
                      }}
                    >
                      {trainer.user_account.id
                        ? `${trainer.user_account.first_name} ${trainer.user_account.last_name}`
                        : "Unassigned"}
                    </Text>
                  </Row>
                )}
              </Col>
              <Col
                align="middle"
                justify="center"
                style={{ position: "relative" }}
              >
                {!client?.isGuest && !client?.user_account && !formVisible ? (
                  <Tooltip
                    title={
                      !client?.email
                        ? "Email is required to create an account"
                        : ""
                    }
                  >
                    <button
                      textStyle={{ fontSize: "3vw" }}
                      style={styles.createButton}
                      onClick={createAlert}
                      disabled={!client?.email}
                    >
                      Create Account
                    </button>
                  </Tooltip>
                ) : client?.user_account ? (
                  <Tooltip
                    title={
                      !client?.email
                        ? "Email is required to reset password"
                        : ""
                    }
                  >
                    <button
                      textStyle={{ fontSize: "3vw" }}
                      style={styles.createButton}
                      onClick={() => {
                        dispatch(resetPass(client?.email));
                        setResetLoading(true);
                      }}
                      disabled={!client?.email}
                    >
                      {resetLoading ? (
                        <Spin size="small" />
                      ) : (
                        <span>{passMessage}</span>
                      )}
                    </button>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {user?.profile?.allow_edit_ranges && (
                  <Col style={{ marginTop: ".25vw" }}>
                    <Button
                      textStyle={{ fontSize: "3vw" }}
                      style={styles.createButton}
                      onClick={showRangeModal}
                    >
                      Edit Ranges
                    </Button>
                  </Col>
                )}
              </Col>
            </Row>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center the content horizontally
                justifyContent: "center", // Center the content vertically
                maxHeight: "45vw"
              }}
            >
              <div
                style={{
                  right: "1vw",
                  top: 0,
                  position: "absolute",
                  cursor: "pointer",
                  color: Theme.PRIMARY
                }}
                onClick={() => openUpdateForm()}
              >
                <IoClose size={25} />
              </div>
              <label htmlFor="profileImageUpload">
                <img
                  src={clientPhoto}
                  style={{
                    width: "5.5vw",
                    height: "5.5vw",
                    position: "relative",

                    alignSelf: "center",
                    objectFit: "cover",
                    borderRadius: "100%",
                    // marginBottom: "1vw",
                    cursor: "pointer"
                  }}
                  alt="Client"
                  onClick={handleImageClick}
                />
                <div
                  onClick={handleImageClick}
                  style={{
                    height: "1.5vw",
                    width: "1.5vw",
                    bottom: "1.75vw",
                    left: "1.75vw",
                    position: "relative",
                    borderRadius: 50,
                    cursor: "pointer",
                    opacity: 0.85,
                    backgroundImage:
                      "linear-gradient(15deg, #2d2d2d 0%, #2d2d2d 40%)"
                  }}
                >
                  <IoCameraOutline
                    style={{
                      // display: "block",
                      // margin: "0 auto",
                      position: "absolute",
                      zIndex: 60,
                      top: ".25vw",
                      right: ".25vw",
                      // left: "17.5%",
                      fontSize: "1vw",
                      color: Theme.WHITE
                    }}
                  />
                </div>
                <input
                  id="imageInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </label>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  first_name: first_name,
                  last_name: last_name,
                  email: email,
                  dob: dob,
                  weight: weight,
                  height_feet: height_feet,
                  height_inches: height_inches,
                  weight_kg: weight_kg,
                  height_cm: height_cm,
                  trainer_id:
                    user.account_type === 2
                      ? selectedTrainer?.user_account?.id?.toString()
                      : user?.profile?.id?.toString()
                }}
                onSubmit={handleSubmit}
                validateOnChange={true} // Enable validation on change
                validateOnBlur={true}
                validationSchema={yup.object().shape({
                  first_name: yup.string().required("Required"),
                  last_name: yup.string().required("Required"),
                  email: yup
                    .string()
                    .email("Must be a valid email address")
                    .when([], {
                      is: () => !!client?.user_account, // Check if client?.user_account is not null
                      then: (schema) => schema.required("Required"), // Make email required if the condition is true
                      otherwise: (schema) => schema.notRequired() // Make email not required otherwise
                    }),
                  dob: yup
                    .string()
                    .matches(
                      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
                      "MM/DD/YYYY"
                    )
                    .required("Required"),
                  weight: yup
                    .number()
                    .typeError("Must be a number")
                    .min(1, "1-999")
                    .max(999, "1-999")
                    .nullable(),
                  height_feet: yup
                    .number()
                    .typeError("Must be a number")
                    .max(8, "Max 8 ft")
                    .nullable(),
                  height_inches: yup
                    .number()
                    .typeError("Must be a number")
                    .max(12, "Max 12 in")
                    .nullable(),
                  weight_kg: yup
                    .number()
                    .typeError("Must be a number")
                    .min(1, "1-999")
                    .max(999, "1-999")
                    .nullable(),
                  height_cm: yup
                    .number()
                    .typeError("Must be a number")
                    .min(1, "1-999")
                    .max(999, "1-999")
                    .nullable()
                })}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values,

                  touched,
                  submitForm
                }) => (
                  <Col align="center" className={css(styles.registerContainer)}>
                    <div className={css(styles.block, styles.center)}>
                      <Row
                        justify={"center"}
                        className={css(styles.row)}
                        style={{
                          bottom: 25,
                          marginBottom:
                            errors.last_name || errors.first_name
                              ? "2vw"
                              : "1vw"
                        }}
                      >
                        <div className={css(styles.column, styles.margin5)}>
                          <BootstrapInput
                            value={values.first_name}
                            placeholder="First Name"
                            onChange={(e) => {
                              handleChange(e);
                              setFirstNameInputValue(e.target.value);
                            }}
                            width={"9vw"}
                            name="first_name"
                          />
                          <Col
                            align="start"
                            justify="center"
                            style={{ left: "3vw", position: "relative" }}
                          >
                            <Text
                              style={{
                                color: "red",
                                position: "absolute",
                                textAlign: "center",
                                fontSize: ".65vw"
                              }}
                            >
                              {errors.first_name}
                            </Text>
                          </Col>
                        </div>
                        <div
                          className={css(styles.column, styles.margin5)}
                          style={{ marginLeft: "1vw" }}
                        >
                          <BootstrapInput
                            value={last_name}
                            placeholder="Last Name"
                            onChange={(e) =>
                              setlastNameInputValue(e.target.value)
                            }
                            width={"9vw"}
                          />
                          <Col
                            align="start"
                            justify="center"
                            style={{ left: "3vw", position: "relative" }}
                          >
                            <Text
                              style={{
                                color: "red",
                                position: "absolute",
                                textAlign: "center",
                                fontSize: ".65vw"
                              }}
                            >
                              {errors.last_name}
                            </Text>
                          </Col>
                        </div>
                      </Row>
                      <div
                        className={css(styles.column)}
                        style={{
                          bottom: 25,
                          marginBottom: errors.email ? "2vw" : "1vw"
                        }}
                      >
                        <BootstrapInput
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmailInputValue(e.target.value)}
                          width={"19vw"}
                        />
                        <Col
                          align="start"
                          justify="center"
                          style={{ position: "relative", left: ".75vw" }}
                        >
                          <Text
                            style={{
                              color: "red",
                              position: "absolute",
                              textAlign: "center",
                              fontSize: ".65vw"
                            }}
                          >
                            {errors.email}
                          </Text>
                        </Col>
                      </div>
                      <Row
                        justify={"center"}
                        align={"middle"}
                        className={css(styles.row)}
                        style={{
                          bottom: 25,
                          marginBottom: errors.dob ? "2vw" : "1vw"
                        }}
                      >
                        <div
                          className={css(styles.column)}
                          style={{ marginRight: "1vw" }}
                        >
                          <BootstrapInput
                            value={dob}
                            placeholder="Date of Birth"
                            onChange={(e) =>
                              setDob(formatDateString(e.target.value))
                            }
                            width={"9vw"}
                          />
                          <Col
                            align="start"
                            justify="center"
                            style={{
                              left: "3vw",
                              position: "relative"
                            }}
                          >
                            <Text
                              style={{
                                color: "red",
                                position: "absolute",
                                textAlign: "center",
                                fontSize: ".65vw"
                              }}
                            >
                              {errors.dob}
                            </Text>
                          </Col>
                        </div>
                        <CustomSelect
                          defaultValue="M"
                          options={[
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" }
                          ]}
                          placeholder={"Gender"}
                          value={gender}
                          onChange={(value) => setGender(value)}
                          width="9vw"
                          lightModeBackground="#f1f1fb"
                          darkModeBackground="#000"
                        />
                      </Row>
                      {user?.profile?.measurement === "Imperial" ? (
                        <Row
                          justify={"center"}
                          align={"middle"}
                          className={css(styles.row)}
                          style={{ bottom: 25, marginBottom: "1vw" }}
                        >
                          <div className={css(styles.column)}>
                            <BootstrapInput
                              value={weight}
                              placeholder="Weight"
                              onChange={(e) =>
                                setWeightInputValue(e.target.value)
                              }
                              width={"5.75vw"}
                            />
                          </div>
                          <div
                            className={css(styles.column)}
                            style={{
                              marginLeft: ".75vw",
                              marginRight: ".75vw"
                            }}
                          >
                            <BootstrapInput
                              value={height_feet}
                              placeholder="Height (ft)"
                              onChange={(e) =>
                                setHeightFeetInputValue(e.target.value)
                              }
                              width={"5.75vw"}
                            />
                          </div>
                          <div className={css(styles.column)}>
                            <BootstrapInput
                              value={height_inches}
                              placeholder="Height (in)"
                              onChange={(e) =>
                                setHeightInchesInputValue(e.target.value)
                              }
                              width={"5.75vw"}
                            />
                          </div>
                        </Row>
                      ) : (
                        <Row
                          justify={"center"}
                          align={"middle"}
                          className={css(styles.row)}
                          style={{ bottom: 25, marginBottom: "1vw" }}
                        >
                          <div className={css(styles.column)}>
                            <BootstrapInput
                              value={weight_kg}
                              placeholder="Weight (kg)"
                              onChange={(e) =>
                                setWeightKgInputValue(e.target.value)
                              }
                              width={"9vw"}
                            />
                          </div>
                          <div
                            className={css(styles.column)}
                            style={{ marginLeft: "1vw" }}
                          >
                            <BootstrapInput
                              value={height_cm}
                              placeholder="Height (cm)"
                              onChange={(e) =>
                                setHeightcmInputValue(e.target.value)
                              }
                              width={"9vw"}
                            />
                          </div>
                        </Row>
                      )}
                      {trainers.length > 1 && (
                        <Col
                          align="center"
                          justify="center"
                          style={{ marginTop: ".5vw" }}
                        >
                          <DebounceSelect
                            style={{ width: "15vw", bottom: 3 }}
                            showSearch
                            suffixIcon={null}
                            defaultValue={
                              trainer.user_account.first_name +
                              " " +
                              trainer.user_account.last_name
                            }
                            placeholder={
                              <span
                                style={{
                                  color:
                                    Theme.BACKGROUND === "#000"
                                      ? "#fff"
                                      : "#000",
                                  fontSize: ".65vw",
                                  top: ".05vw",
                                  position: "relative",
                                  fontWeight: "normal"
                                }}
                              >
                                Search Trainers
                              </span>
                            }
                            lightModeBackground={
                              Theme.BACKGROUND === "#000"
                                ? null
                                : Theme.BACKGROUND
                            }
                            darkModeBackground={
                              Theme.BACKGROUND === "#000"
                                ? Theme.BACKGROUND
                                : null
                            }
                            optionFilterProp="children"
                            onChange={(trainer) => setSelectedTrainer(trainer)}
                            fetchOptions={fetchTrainers}
                            debounceTimeout={800}
                          />
                        </Col>
                      )}

                      <Row
                        style={{
                          bottom: "10%",
                          position: "relative",

                          paddingBottom: 30
                        }}
                      >
                        {!client?.isGuest && (
                          <Button
                            textStyle={{ fontSize: "3vw" }}
                            style={{
                              // elevation: 3,
                              borderWidth: 0,

                              marginTop: "5%",
                              marginLeft: "1vw",
                              marignRight: "1vw",
                              width: "40%",
                              position: "relative",

                              backgroundColor: "#000"
                            }}
                            onClick={deleteAlert}
                          >
                            <Text style={styles.buttontext}>
                              Delete {user?.profile?.client_label}
                            </Text>
                          </Button>
                        )}
                        <Button
                          textStyle={{ fontSize: "3vw" }}
                          style={styles.button}
                          onClick={() => doSubmit()}
                        >
                          <Text style={styles.buttontext}>Save</Text>
                        </Button>
                      </Row>
                    </div>
                  </Col>
                )}
              </Formik>
            </div>
          )}
        </Col>
      )}
      <Modal
        visible={isRangeModalVisible}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        onCancel={handleRangeModalCancel}
        title={[
          <Row
            style={{
              background: Theme.CARD,

              zIndex: 999
            }}
          >
            <Button
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #C04848 80%)",
                border: 0,
                color: "white",
                marginLeft: "1vw"
              }}
              key="cancel"
              onClick={handleRangeModalCancel}
            >
              Cancel
            </Button>

            <Button
              style={{
                backgroundImage: "linear-gradient(45deg, #fff 0%, #fff 80%)",
                border: 0,
                color: "black",
                marginLeft: "1vw"
              }}
              key="reset"
              onClick={handleRangeModalReset}
            >
              Reset
            </Button>

            <Button
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                border: 0,
                marginLeft: "1vw"
              }}
              key="save"
              type="primary"
              onClick={updateRanges}
            >
              Save
            </Button>
          </Row>
        ]}
        width={"90%"}
        centered={true}
        bodyStyle={{ padding: 0 }}
      >
        <Row justify={"center"}>
          <RangeTemplates
            rangeProfile={rangeProfile}
            setRangeProfile={setRangeProfile}
            showModal={showRangeInfoModal}
          />
        </Row>

        <Modal
          visible={resetModalVisible}
          onCancel={closeResetModal}
          footer={null}
          width={"40%"}
          centered={true}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="center">
            <div className={css(styles2.title)}>
              <p
                className={css(styles2.titleText)}
                style={{ color: Theme.TEXT }}
              >
                Select Range Template
              </p>
            </div>

            <Row
              justify={"center"}
              style={{ bottom: 25, marginBottom: ".75vw" }}
            >
              <CustomSelect
                style={{ width: "9vw" }}
                showSearch
                suffixIcon={null}
                options={profileOptions}
                value={selectedRangeTemplate}
                placeholder={
                  <span
                    style={{
                      color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                      fontSize: ".7vw",
                      top: ".05vw",
                      position: "relative",
                      fontWeight: "normal"
                    }}
                  >
                    Choose Range Profile
                  </span>
                }
                size={"large"}
                lightModeBackground="#f1f1fb"
                darkModeBackground="#000"
                onChange={(value) => setSelectedRangeTemplate(value)}
              ></CustomSelect>
            </Row>
            <button
              className={css(styles2.createButton)}
              type="submit"
              onClick={handleRangeReset}
              disabled={!selectedRangeTemplate}
              style={{
                cursor: !selectedRangeTemplate ? "not-allowed" : "pointer",
                backgroundColor: !selectedRangeTemplate ? "#ccc" : undefined,
                color: !selectedRangeTemplate ? "#666" : undefined,
                opacity: !selectedRangeTemplate ? 0.6 : 1
              }}
            >
              <div className={css(styles2.buttonGradient)}>
                <p className={css(styles2.buttontext)}>Reset</p>
              </div>
            </button>
          </Col>
        </Modal>

        <Modal
          visible={isRangeInfoModalVisible}
          onCancel={() => seRangeInfoModalVisible(false)}
          width={modalContent.name === "CMJ" ? "55vw" : "25vw"}
          bodyStyle={{
            paddingBottom: "10vw",
            paddingTop: "1vw",
            paddingLeft: "1vw",
            paddingRight: "1vw",
            minHeight: "25vw"
          }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
          footer={[<div></div>]}
        >
          <Title style={{ color: Theme.TEXT }} level={3}>
            {modalContent.name}
          </Title>
          {modalContent.description &&
            modalContent.description.map((desc, index) => (
              <p key={index} style={{ fontSize: "1.5rem", color: Theme.TEXT }}>
                {desc}
              </p>
            ))}
        </Modal>
      </Modal>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients.clients
});

export default connect(mapStateToProps, { createClient })(ClientHeader);

let imageSize = 100;

const createStyles = (theme) => ({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center",
    position: "relative"
  },

  button: {
    // elevation: 3,
    borderWidth: 0,

    marginTop: "5%",
    marginLeft: "1vw",
    marignRight: "1vw",
    width: "40%",
    position: "relative",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)"
    // backgroundColor: 'PRIMARY',
  },
  createButton: {
    // elevation: 3,
    borderWidth: 0,
    padding: 5,
    width: "6vw",
    borderRadius: 5,
    marginTop: "5%",
    color: "white",
    fontSize: ".65vw",
    fontWeight: "bold",
    position: "relative",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    height: "1.5vw",
    cursor: "pointer"
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "600",
    fontSize: ".75vw",
    paddingHorizontal: 5,
    alignSelf: "center"
    // fontSize: 1,
  },
  deleteButtontext: {
    color: theme.WHITE,
    fontWeight: "600",
    fontSize: ".65vw",
    // paddingHorizontal: 5,
    alignSelf: "center"
    // fontSize: 1,
  },
  header: {
    width: "90%",
    alignSelf: "center",

    backgroundColor: theme.PRIMARY,
    borderWidth: 0,

    borderRadius: 5,

    marginVertical: 5
  },
  headerName: {
    fontSize: 22,
    marginBottom: "2%",
    color: theme.PRIMARY
  },
  updateTitle: {
    fontSize: 18,
    left: "1%",
    marginBottom: "2%",
    color: theme.PRIMARY
  },
  headerSmallInput: {
    fontSize: 15,
    // right: 37.5,
    // alignSelf: "center",
    marginVertical: "3%",
    color: theme.PRIMARY,
    borderBottomColor: theme.PRIMARY,
    borderBottomWidth: 1,
    padding: 5,
    marginHorizontal: 10,
    // borderRadius: 5,
    minWidth: "30%",
    textAlign: "center"
    // shadowColor: theme.PRIMARY,
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 4,
    // shadowOpacity: 0.5,
  },
  headerEmailInput: {
    fontSize: 15,
    // right: 37.5,
    // alignSelf: "center",
    marginVertical: "3%",
    color: theme.PRIMARY,
    borderBottomColor: theme.PRIMARY,
    borderBottomWidth: 1,
    padding: 5,
    marginHorizontal: 10,
    // borderRadius: 5,
    width: "65%",
    textAlign: "center"
    // shadowColor: theme.PRIMARY,
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 4,
    // shadowOpacity: 0.5,
  },
  headerText: {
    fontSize: 13,
    // right: 37.5,
    // alignSelf: "center",

    marginBottom: "2%",
    // fontWeight: "bold",
    color: theme.PRIMARY
    // shadowColor: theme.PRIMARY,
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 4,
    // shadowOpacity: 0.5,
  },
  image: {
    width: imageSize,
    height: imageSize,
    alignSelf: "center",

    marginTop: "5%",
    borderRadius: 100

    // right: 20,
  },

  headerTitle: {
    fontSize: 15,
    // right: 37.5,
    // alignSelf: "center",
    // fontFamily: "Arial",
    marginBottom: "2%",
    fontWeight: "bold",
    color: theme.TEXT
    // shadowColor: theme.PRIMARY,
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 4,
    // shadowOpacity: 0.5,
  },
  linearGradient: {
    // flex: 1,
    // width: '100%',
    // height: 100,
    // paddingLeft: 15,
    // paddingRight: 15,
    // borderRadius: 5,
    // borderBottomRightRadius: 100,
    // borderTopRightRadius: 100,
    // overflow: 'hidden',
    // marginLeft: 5,
    // marginRight: 5,
  },
  bottomView: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0
  },
  modalView: {
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "PRIMARY",
    borderRadius: 5,
    // shadowColor: "black",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 4,
    elevation: 5
  }
});

const styles2 = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },

  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "2%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
    // paddingHorizontal: 5,
  }
});
