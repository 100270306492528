import React from "react";
import Form from "react-bootstrap/Form";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const BootstrapInput = ({
  value,
  placeholder,
  onChange,
  error,
  width,
  required,
  type = "text"
}) => {
  const { Theme } = useSelector((state) => state.auth);

  return (
    <div>
      <Form.Control
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required ? true : false}
        style={{
          width: width || "100%",
          height: "auto",
          borderRadius: "10px",
          borderWidth: 0,
          fontSize: isMobile ? 15 : ".75vw",
          background: Theme.BACKGROUND,
          color: Theme.TEXT,
          outline: "none"
        }}
      />
      {error && (
        <p
          style={{
            position: "relative",
            // top: 55,
            color: "red",
            fontSize: ".75vw"
            // right: '25%'
            // alignSf: 'flex-start'
            // marginLeft: 12,
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default BootstrapInput;
