import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  reset_password,
  reset_password_confirm,
  resetPassSuccess
} from "../redux/actions/auth";
import { Link, useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Card, Col, Input, Modal, Row } from "antd";
import Theme from "../constants/Theme";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Title from "antd/es/skeleton/Title";

const ResetPasswordConfirm = ({ match, reset_password_confirm }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: ""
  });
  const formikRef = useRef();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [newLinkModal, setNewLinkModal] = React.useState(false);
  const [emailInputValue, setEmailInputValue] = useState("");
  const [messageText, setMessageText] = useState("");
  const onSubmit = async () => {
    const email = emailInputValue.toLowerCase();
    dispatch(reset_password(email));
    setTimeout(() => {
      setMessageText(
        "Please check your email for instructions to reset your password"
      );
    }, 750);
  };
  const { resetStatus, passSuccess } = useSelector((state) => state.auth);
  const { new_password, re_new_password } = formData;
  const intialValues = { new_password: "", re_new_password: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const { uid } = useParams();
  const { token } = useParams();

  const handleSubmit2 = async (values, e) => {
    e.preventDefault();
    if (new_password !== re_new_password) {
      setPasswordMismatch(true);
    } else {
      reset_password_confirm(uid, token, new_password, re_new_password);
    }
  };

  const handleSubmit = async (values) => {
    const new_password = values.new_password;
    const re_new_password = values.re_new_password;
    reset_password_confirm(uid, token, new_password, re_new_password);
  };

  // Inside your component
  const history = useHistory();

  const handleButtonClick = () => {
    if (window.innerWidth <= 950) {
      // Adjust the breakpoint as needed
      window.location.href = "https://demotu.page.link/anamalium";
    } else {
      history.push("/sign-in");
    }
  };
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");

  useEffect(() => {
    if (resetStatus === 204) {
      setMessage("Success!");
      setMessageColor("green");
      setModalShow(true);
    } else if (resetStatus === 400) {
      setMessage("Error - Link May Have Expired");
      setMessageColor("red");
    } else if (passwordMismatch === true) {
      setMessage("Passwords Do Not Match");
      setMessageColor("red");
    }
  }, [resetStatus, passwordMismatch]);

  useEffect(() => {
    if (passSuccess) {
      setModalShow(true);
      dispatch(resetPassSuccess());
    }
  }, [passSuccess]);

  return (
    <Col
      justify={"center"}
      align={"middle"}
      style={{ height: "100vh", width: "100vw", background: "black" }}
    >
      <Col
        justify={"center"}
        align={"middle"}
        style={{ background: "transparent" }}
        className="sign-up-header"
      >
        <Card
          className="card-signup header-solid h-full ant-card pt-0"
          style={{
            top: "50vh",
            position: "relative",
            minHeight: "37.5vw",
            width: "75vw",
            boxShadow: "2px 2px 6px rgba(255, 255, 255, 0.15)",
            backgroundColor: "#1A1E23"
          }}
          bordered={false}
          justify={"center"}
          align={"middle"}
        >
          <h3 style={{ color: Theme.COLORS.WHITE }}>Set Password</h3>

          <Formik
            innerRef={formikRef}
            initialValues={{
              new_password: "",
              re_new_password: ""
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              new_password: Yup.string()
                .required("Please Enter Your Password")
                .min(8, "Must Be 8 Characters or More")
                .matches(/[a-z]+/, "Must Contain One Lowercase Character")
                .matches(/[A-Z]+/, "One Contain one Uppercase Character")
                .matches(/[@$!%*#?&]+/, "One Contain one Special Character")
                .matches(/\d+/, "One number"),
              re_new_password: Yup.string()
                .required("Please Re-Type Your Password")
                .oneOf([Yup.ref("new_password")], "Your Passwords Do Not Match")
            })}
          >
            {({ isSubmitting, errors, touched, values, handleSubmit }) => (
              <Form
                style={{
                  marginTop: "5%",
                  marginBottom: "15%",
                  background: "transparent"
                }}
              >
                <div>
                  <Field
                    className="reset-pass-input"
                    style={{
                      width: "100%"
                    }}
                    type="password"
                    name="new_password"
                    placeholder="Password"
                  />
                  {errors.new_password && touched.new_password && (
                    <div style={{ color: "red" }}>{errors.new_password}</div>
                  )}

                  <Field
                    className="reset-pass-input"
                    type="password"
                    name="re_new_password"
                    style={{
                      width: "100%"
                    }}
                    placeholder="Confirm Password"
                  />
                  {errors.re_new_password && touched.re_new_password && (
                    <div style={{ color: "red" }}>{errors.re_new_password}</div>
                  )}
                </div>

                <button
                  style={{
                    marginTop: "10%",
                    borderRadius: 10,
                    backgroundColor: Theme.COLORS.PRIMARY,
                    width: "80%",
                    position: "relative",
                    color: "white",
                    height: 45,
                    border: "none",
                    cursor: "pointer",
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                  }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <p style={{ color: messageColor }}>{message}</p>
                {resetStatus === 400 && (
                  <p style={{ color: "red" }}>
                    Request a new link{" "}
                    <strong
                      style={{ color: "white", fontWeight: "bold" }}
                      onClick={() => setNewLinkModal(true)}
                    >
                      here
                    </strong>
                  </p>
                )}
              </Form>
            )}
          </Formik>

          <Modal
            open={modalShow}
            onCancel={() => setModalShow(false)}
            footer={[null]}
          >
            <Col
              align="middle"
              justify="center"
              style={{ alignSelf: "center" }}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  marginTop: "15%",
                  paddingTop: "5%",
                  fontSize: window.innerWidth <= 950 ? null : "1vw"
                }}
              >
                Password Set!
              </h2>
              <p style={{ textAlign: "center" }}>
                You may return to the app and login
              </p>
              <Button
                className="sign-up-button"
                style={{
                  marginTop: "10%",
                  borderRadius: 10,
                  backgroundColor: Theme.COLORS.PRIMARY,
                  width: "50%",
                  position: "relative",
                  color: "white",
                  height: 45,
                  border: "none",
                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
                onClick={handleButtonClick}
                type="button"
                // className="btn btn-primary"
              >
                Let's Go!
              </Button>
            </Col>
          </Modal>
          <Modal
            open={newLinkModal}
            onCancel={() => setNewLinkModal(false)}
            footer={[null]}
          >
            <Row justify="center" align="center">
              <Col justify="center" align="center" span={24}>
                <div>
                  <Title level={3}>Forgot Your Password?</Title>
                  <h3
                    style={{
                      marginTop: "10%",
                      marginBottom: "5%",
                      // fontSize: "13px",
                      textAlign: "center"
                    }}
                  >
                    Enter your email and we'll send you a link to reset it
                  </h3>
                  <div>
                    <Input
                      // prefix={<Icon type="mail" />}
                      style={{ width: "90%", marginBottom: "2vw" }}
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={emailInputValue}
                      onChange={(e) => setEmailInputValue(e.target.value)}
                    />
                  </div>
                  <Button
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginTop: "5%"
                    }}
                    className="sign-in-button"
                    type="submit"
                    onClick={onSubmit}
                  >
                    Request Link
                  </Button>
                  <div
                    style={{
                      marginTop: "5%",
                      fontSize: ".65vw",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: Theme.COLORS.PRIMARY,
                      width: "75%"
                    }}
                  >
                    {messageText}
                  </div>
                </div>
              </Col>
            </Row>
          </Modal>
        </Card>
      </Col>
    </Col>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
