import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Modal,
  Typography,
  Tabs,
  Button,
  Switch,
  Spin,
  message,
  Input
} from "antd";
import { UserOutlined, FileAddOutlined } from "@ant-design/icons";
import { IoAddCircleOutline, IoPencilSharp } from "react-icons/io5";
import { StyleSheet } from "aphrodite";

import { getProfiles, getSingleProfile } from "../../redux/actions/clients";
import { load_user, updateProfile } from "../../redux/actions/auth";
import { updateOrg, getOrg } from "../../redux/actions/organization";
import BootstrapSearch from "../common/Search";
import CreateRangeProfileTemplate from "./CreateRangeProfileTemplateForm";
import rangePic from "../../assets/images/rangeprofile.png";
import AOS from "aos";
import "aos/dist/aos.css";
import BootstrapInput from "../common/Input";
const { Title, Text } = Typography;

function RangeProfiles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, Theme } = useSelector((state) => state.auth);
  const [rangeTemplateCreateModalVisible, setRangeTemplateCreateModal] =
    useState(false);
  const [isLoading, setLoading] = useState(true);
  const [editColors, setEditColors] = useState(false);
  const [yellowBottom, setYellowBottom] = useState(
    user?.profile?.scoring_colors?.yellow || 65
  );
  const [greenBottom, setGreenBottom] = useState(
    user?.profile?.scoring_colors?.green || 80
  );
  const [searchInput, setSearchInput] = useState("");
  const [colorError, setColorError] = useState("");
  const { profiles } = useSelector((state) => state.clients);
  const { organization } = useSelector((state) => state.organization);
  const fetchUser = () => dispatch(load_user());

  useEffect(() => {
    AOS.init();
    fetchUser();
    dispatch(getProfiles());
    if (user?.profile?.account_type == 2) {
      dispatch(getOrg(user?.profile?.organization));
    }
    setLoading(false);
  }, []);

  const handleClick = (id) => {
    dispatch(getSingleProfile(id));
    history.push(`/templates/ranges/${id}`);
  };

  const handleAllowTemplatesChange = (checked) => {
    dispatch(
      updateOrg(user?.profile?.organization, { allow_templates: checked })
    ).catch(() => message.error("Failed to update organization settings"));
  };

  const handleAllowEditRangesChange = (checked) => {
    dispatch(
      updateOrg(user?.profile?.organization, { allow_edit_ranges: checked })
    ).catch(() => message.error("Failed to update organization settings"));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const handleUpdateColors = () => {
    setColorError("");
    if (greenBottom <= yellowBottom) {
      setColorError("Green's min must be higher than yellow's max");
      return;
    }
    if (user?.profile?.account_type === 2) {
      dispatch(
        updateOrg(user?.profile?.organization, {
          scoring_colors: {
            yellow: parseInt(yellowBottom),
            green: parseInt(greenBottom)
          }
        })
      );

      setEditColors(false);
    }
    if (user?.profile?.account_type === 1) {
      dispatch(
        updateProfile(user?.profile?.id, {
          scoring_colors: {
            yellow: parseInt(yellowBottom),
            green: parseInt(greenBottom)
          }
        })
      );
    }
    setEditColors(false);
  };

  const filteredProfiles = profiles
    ? profiles.filter((profile) =>
        profile.name?.toLowerCase().includes(searchInput)
      )
    : [];

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Card
        bordered={true}
        style={{
          width: "60vw",
          background: Theme.CARD,
          borderWidth: 0
        }}
        className="criclebox cardbody"
      >
        {user?.profile?.allow_templates && (
          <Row
            align={"middle"}
            justify={"center"}
            className="project-ant"
            style={{ marginBottom: "1.5vw", width: "99.5%" }}
          >
            <BootstrapSearch
              placeholder="Search Templates"
              value={searchInput}
              width="15vw"
              onChange={handleSearchChange}
            />

            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => setRangeTemplateCreateModal(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 12,
                  height: "2.2vw",
                  width: "9vw",
                  fontWeight: "bold",

                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                <Row align={"middle"}>
                  <IoAddCircleOutline size={"1vw"} style={{}} />
                  <div>
                    <Text style={{ fontSize: ".75vw", color: "white" }}>
                      New Template
                    </Text>
                  </div>
                </Row>
              </Button>
            </Col>
            {(user?.profile?.account_type === 2 ||
              !user.profile.organization) && (
              <Col
                style={{ gap: 16, fontWeight: "bold", textAlign: "left" }}
                align={"start"}
                justify={"start"}
              >
                <Row
                  align={"start"}
                  justify={"start"}
                  style={{ gap: 8, textAlign: "left" }}
                >
                  <Text style={{ position: "relative", color: Theme.TEXT }}>
                    Scoring Color Ranges{" "}
                    {user?.profile?.account_type === 2 && (
                      <span>For {organization?.name}</span>
                    )}
                  </Text>
                  {!editColors ? (
                    <span
                      style={{
                        top: 0,
                        left: ".275vw",
                        position: "relative",
                        color: Theme.PRIMARY,
                        cursor: "pointer"
                      }}
                      onClick={() => setEditColors(!editColors)}
                    >
                      <IoPencilSharp size={".50vw"} />
                    </span>
                  ) : (
                    <Row style={{ gap: 8 }}>
                      <Button
                        style={{
                          height: "1vw",
                          background: "white",
                          color: "black",
                          fontSize: 10,
                          width: "3vw",
                          borderWidth: 0
                        }}
                        onClick={() => setEditColors(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          height: "1vw",
                          background: "black",
                          color: "white",
                          fontSize: 10,
                          width: "3vw",
                          borderWidth: 0
                        }}
                        onClick={handleUpdateColors}
                      >
                        Save
                      </Button>
                    </Row>
                  )}
                </Row>
                <Row
                  align={"start"}
                  justify={"start"}
                  style={{ gap: 12, textAlign: "left" }}
                >
                  <Text style={{ color: Theme.TEXT, fontWeight: 600 }}>
                    Red{" "}
                  </Text>
                  <Text
                    style={{
                      color: Theme.TEXT2,
                      fontWeight: "normal",
                      fontSize: ".75vw"
                    }}
                  >
                    0-
                    {yellowBottom - 1 !== -1 ? yellowBottom - 1 : 0}
                  </Text>
                  <Text style={{ color: Theme.TEXT, fontWeight: 600 }}>
                    Yellow{" "}
                  </Text>
                  <Text
                    style={{
                      color: Theme.TEXT2,
                      fontWeight: "normal",
                      fontSize: ".75vw"
                    }}
                  >
                    <Row align={"middle"} style={{ fontSize: ".75vw" }}>
                      {editColors ? (
                        <BootstrapInput
                          value={yellowBottom}
                          onChange={(e) => setYellowBottom(e.target.value)}
                          width={50}
                          style={{ width: 40 }}
                          required={true}
                        />
                      ) : (
                        <span>{yellowBottom}</span>
                      )}
                      -{greenBottom - 1 !== -1 ? greenBottom - 1 : 0}
                    </Row>
                  </Text>
                  <Text style={{ color: Theme.TEXT, fontWeight: 600 }}>
                    Green{" "}
                  </Text>
                  <Text
                    style={{
                      color: Theme.TEXT2,
                      fontWeight: "normal",
                      fontSize: ".75vw"
                    }}
                  >
                    <Row align={"middle"} style={{ fontSize: ".75vw" }}>
                      {editColors ? (
                        <BootstrapInput
                          value={greenBottom}
                          onChange={(e) => setGreenBottom(e.target.value)}
                          width={50}
                          style={{ width: 40 }}
                          required={true}
                        />
                      ) : (
                        <span>{greenBottom}</span>
                      )}
                      -100
                    </Row>
                  </Text>
                </Row>
                {colorError && (
                  <Text
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      fontSize: ".65vw"
                    }}
                  >
                    {colorError}
                  </Text>
                )}
              </Col>
            )}
          </Row>
        )}
        {user?.profile?.account_type === 2 && (
          <hr
            width={1}
            style={{
              color: Theme.GRAD6,

              width: "95%"
            }}
          />
        )}
        {user?.profile?.account_type === 2 && (
          <Row
            align={"middle"}
            justify={"center"}
            style={{
              marginTop: "2vw",
              marginBottom: "2vw",
              marginLeft: "1.5vw",
              gap: "5vw"
            }}
          >
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Switch
                style={{ marginRight: "0.5rem" }}
                size="small"
                checked={organization?.allow_templates}
                onChange={handleAllowTemplatesChange}
              />
              <Text style={{ color: Theme.TEXT, fontSize: ".65vw" }}>
                Allow Trainers to Create Profile Templates
              </Text>
            </Col>
            <Col
              style={{
                display: "flex",

                alignItems: "center"
              }}
            >
              <Switch
                size="small"
                style={{ marginRight: "0.5rem" }}
                checked={organization?.allow_edit_ranges}
                onChange={handleAllowEditRangesChange}
              />
              <Text style={{ color: Theme.TEXT, fontSize: ".65vw" }}>
                Allow Trainers to Edit Individual Client Ranges
              </Text>
            </Col>
          </Row>
        )}
        {/* Render Profiles */}
        {filteredProfiles.length > 0 ? (
          <div
            style={{ paddingLeft: "4vw" }}
            className="ant-list-box table-responsive"
          >
            <table className="width-100">
              <thead>
                <tr>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    NAME
                  </th>
                  <th
                    style={{
                      fontSize: ".85vw",
                      right: "3.5vw",
                      position: "relative"
                    }}
                  ></th>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    DATE MODIFIED
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProfiles.map((d, index) => (
                  <tr key={index} onClick={() => handleClick(d.id, d.trainer)}>
                    <td
                      style={{
                        fontSize: ".75vw",
                        width: "50%",
                        cursor: "pointer"
                      }}
                    >
                      <Row style={{ fontSize: "1vw" }}>
                        <p
                          style={{
                            position: "relative",

                            fontSize: ".85vw",
                            color: Theme.TEXT
                          }}
                        >
                          {d.name}
                        </p>
                      </Row>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      <span className="text-xs font-weight-bold"></span>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      {new Date(d.date_modified).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col
            style={{
              textAlign: "center",
              marginTop: "3vw",
              marginBottom: "2vw"
            }}
          >
            <FileAddOutlined
              style={{ fontSize: "3vw", color: Theme.PRIMARY }}
            />
            <Text
              style={{
                display: "block",
                color: Theme.TEXT,
                marginTop: 10,
                fontSize: "1vw"
              }}
            >
              No Templates
            </Text>
          </Col>
        )}
      </Card>

      <Modal
        open={rangeTemplateCreateModalVisible}
        onCancel={() => setRangeTemplateCreateModal(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row style={{ padding: "5%" }}>
          <Col span={24}>
            <CreateRangeProfileTemplate
              closeModal={setRangeTemplateCreateModal}
            />
          </Col>
        </Row>
      </Modal>
    </Col>
  );
}

export default RangeProfiles;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
