import React from "react";
import { Card, Col, Row, Modal, Input, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useSelector } from "react-redux";
import RenderProfileSection from "./RenderProfileSection";
import useProfileFieldManager from "./useProfileFieldManager";
import BootstrapInput from "../common/Input";

const PsychNutrition = ({
  editing,
  onUpdateField,
  setEditing,
  handleSave,
  profile,
  setProfile
}) => {
  const { Theme } = useSelector((state) => state.auth);

  const {
    isModalVisible,
    setIsModalVisible,
    newFieldName,
    setNewFieldName,
    newFieldValue,
    setNewFieldValue,
    handleAddField,
    handleSaveField
  } = useProfileFieldManager(profile, setProfile, handleSave);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[
          <Button type="primary" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              handleSaveField();
            }}
          >
            Save
          </Button>
        ]}
      >
        <Text
          style={{
            color: Theme.TEXT,
            paddingTop: "1vw",
            marginBottom: "1vw",
            fontSize: ".9vw"
          }}
        >
          Add New Field
        </Text>
        <BootstrapInput
          placeholder="Field Name"
          value={newFieldName}
          onChange={(e) => setNewFieldName(e.target.value)}
          style={{ marginBottom: "1vw", marginTop: "1vw" }}
        />
        <BootstrapInput
          placeholder="Initial Value (Optional)"
          value={newFieldValue}
          onChange={(e) => setNewFieldValue(e.target.value)}
        />
      </Modal>
      <Row style={{ width: "79vw" }}>
        <Card
          style={{
            bottom: 75,
            width: "48%",
            marginRight: "1vw",
            zIndex: 100,
            position: "relative",
            background: Theme.CARD,
            borderWidth: 0,
            right: "8.75vw"
          }}
        >
          <Col align={"start"}>
            <Text
              style={{
                fontSize: "1vw",
                color: Theme.TEXT,
                alignSelf: "flex-start",
                fontWeight: 800
              }}
            >
              Psychological
              <PlusCircleOutlined
                style={{
                  marginLeft: ".5vw",
                  fontSize: "1vw",
                  color: Theme.BLUE,
                  cursor: "pointer"
                }}
                onClick={() => handleAddField("psychological_profile")}
              />
            </Text>
          </Col>
          <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
            <RenderProfileSection
              section={profile?.psychological_profile || []}
              sectionKey="psychological_profile"
              editing={editing}
              onUpdateField={onUpdateField}
            />
          </Row>
        </Card>
        <Card
          style={{
            bottom: 75,
            width: "48%",
            zIndex: 100,
            position: "relative",
            background: Theme.CARD,
            borderWidth: 0,
            right: "8.75vw"
          }}
        >
          <Col align={"start"}>
            <Text
              style={{
                fontSize: "1vw",
                color: Theme.TEXT,
                alignSelf: "flex-start",
                fontWeight: 800
              }}
            >
              Nutrition
              <PlusCircleOutlined
                style={{
                  marginLeft: ".5vw",
                  fontSize: "1vw",
                  color: Theme.BLUE,
                  cursor: "pointer"
                }}
                onClick={() => handleAddField("nutritional_profile")}
              />
            </Text>
          </Col>
          <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
            <RenderProfileSection
              section={profile?.nutritional_profile}
              sectionKey="nutritional_profile"
              editing={editing}
              onUpdateField={onUpdateField}
            />
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default PsychNutrition;
