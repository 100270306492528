import React, { useState, useEffect } from "react";
import { Col, Card, Row, Typography } from "antd";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import ProgressProvider from "../chart/ProgressProvider";
import ScoreCircle from "../chart/ScoreCircle";

const { Text } = Typography;

const ResultsScores = ({
  isRowLayout,
  scoreArray,
  headerClass,
  headerTitleClass,
  createdOn
}) => {
  const [chartWidth, setChartWidth] = useState("8vw");
  const { Theme } = useSelector((state) => state.auth);
  const handleResize = debounce(() => {
    const containerWidth = Math.min(Math.round(window.innerWidth * 0.05));
    setChartWidth(containerWidth);
  }, 200);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Col>
      <Card
        style={{
          width: "39.75vw", // Adjust this as needed, but ensure it's wide enough
          justifyContent: "center",
          borderRadius: 10,
          shadowColor: Theme.BLACK,
          shadowOffset: { width: 0, height: 1.5 },
          shadowRadius: 1,
          shadowOpacity: 0.8,
          borderWidth: 0,
          backgroundColor: Theme.CARD,
          height: "14vw", // Adjust as needed
          padding: "1vw", // Add some padding inside the card
          paddingBottom: "2vw"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Ensure content is laid out in a column
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensure div takes the full height of the card
            width: "100%" // Ensure div takes the full width of the card
          }}
        >
          <Col
            style={{
              marginBottom: "1vw",
              textAlign: "center",
              width: "100%",
              bottom: ".5vw",
              position: "relative"
            }}
          >
            {" "}
            {/* Ensure this is centered and takes full width */}
            <Text
              style={{
                marginTop: "0vw",
                color: Theme.TEXT,
                fontSize: ".9vw",
                fontWeight: "bold"
              }}
            >
              Date of Assessment
            </Text>
            <Col>
              <Text
                style={{
                  fontSize: ".80vw",
                  marginTop: "0vw",
                  fontWeight: "600",
                  color: Theme.PRIMARY,
                  fontSize: ".75vw"
                }}
              >
                {createdOn.toLocaleDateString()}
              </Text>
            </Col>
          </Col>
          <Row
            align="middle"
            justify="space-around" // Adjust for equal spacing
            style={{
              width: "100%", // Ensure the row takes the full width
              margin: 0, // Reset any default margins
              paddingBottom: "1vw"
            }}
          >
            {scoreArray
              .filter((item) => !isNaN(item.score))
              .map((score, index) => (
                <Col
                  key={index}
                  align="middle"
                  justify="center"
                  style={{ width: "22%" }}
                >
                  <ProgressProvider valueStart={0} valueEnd={score.score}>
                    {(value) => <ScoreCircle height={"4.5vw"} score={value} />}
                  </ProgressProvider>
                  <Text
                    style={{
                      color: Theme.TEXT,
                      fontSize: ".65vw",
                      marginTop: "1vw",
                      position: "relative",

                      fontWeight: "bold"
                    }}
                  >
                    {score.title}
                  </Text>
                </Col>
              ))}
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default ResultsScores;
