import React, { useState, useEffect } from "react";
import { Col, Modal, Typography } from "antd";
import "../../assets/styles/calendar.css"; // Import your external CSS
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import CreateWorkoutForm from "./CreateWorkoutForm";
import WorkoutDetails from "./WorkoutDetails";

const { Text } = Typography;

const WorkoutCalendar = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const { Theme } = useSelector((state) => state.auth);
  const { workouts } = useSelector((state) => state.workouts);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editWorkoutData, setEditWorkoutData] = useState(null);

  // Effect to handle modal visibility when isCreating changes
  useEffect(() => {
    if (selectedDate !== null) {
      if (isCreating) {
        setCreateModalOpen(true);
      } else {
        setEditModalOpen(true);
      }
    }
  }, [isCreating]);

  const handleDateClick = (date) => {
    const workout = getWorkoutForDate(date);

    setSelectedDate(date);

    if (workout) {
      // If there's a workout, set the data in the state for prefilling
      setEditWorkoutData(workout);

      // Open the modal for editing
      setEditModalOpen(true);
    } else {
      // If no workout, open the modal for creating a new workout
      setCreateModalOpen(true);
    }
  };

  // Function to get the workout data for a specific date
  const getWorkoutForDate = (date) => {
    return workouts.find((workout) => {
      const workoutDate = new Date(`${workout.date}T00:00:00Z`);
      return (
        workoutDate.getUTCDate() === date.getUTCDate() &&
        workoutDate.getUTCMonth() === date.getUTCMonth() &&
        workoutDate.getUTCFullYear() === date.getUTCFullYear()
      );
    });
  };

  const getDayClassName = (date) => {
    const baseClassName = "react-calendar__month-view__days__day";

    const workout = getWorkoutForDate(date);
    const isCompleted = workout && workout.completed;

    if (workout) {
      if (isCompleted) {
        return `${baseClassName} workout-day-completed ${Theme.PRIMARY} ${
          Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
        }`;
      } else {
        return `${baseClassName} workout-day ${Theme.PRIMARY} ${
          Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
        }`;
      }
    }

    return `${baseClassName} ${
      Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
    }`;
  };

  const handleCloseModal = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setSelectedDate(null);
    setIsCreating(false);
    setSelectedDate(null);
  };

  return (
    <Col
      style={{
        zIndex: 100,
        flex: 1,
        position: "relative",
        width: "35vw",
        minHeight: "15vw",
        marginBottom: 10,
        background: Theme.CARD,
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Col
        justify={"center"}
        align={"center"}
        style={{
          zIndex: 100,
          flex: 1,
          position: "relative",
          width: "30vw",
          right: ".5vw",
          background: Theme.CARD
        }}
      >
        <Calendar
          onClickDay={handleDateClick}
          tileClassName={({ date }) => getDayClassName(date)}
          className={`workout  ${
            Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
          }`}
        />
        <Modal
          open={createModalOpen}
          onCancel={handleCloseModal}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <CreateWorkoutForm
            initialDate={selectedDate}
            closeModal={handleCloseModal}
          />
        </Modal>
        <Modal
          open={editModalOpen}
          onCancel={handleCloseModal}
          cancelButtonProps={{ style: { display: "none" } }}
          // closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"60vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <WorkoutDetails
            setModalOpen={handleCloseModal}
            workoutData={editWorkoutData}
            editModalOpen={editModalOpen}
            Theme={Theme}
          />
        </Modal>
      </Col>
    </Col>
  );
};

export default WorkoutCalendar;
