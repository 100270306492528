import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Input,
  Button,
  Checkbox,
  Card,
  Select,
  Row,
  Typography,
  Col
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createClient,
  getClients,
  getProfiles,
  resetClientStatus,
  resetProfileStatus
} from "../../redux/actions/clients";
import {
  fetchTrainers,
  getTrainers,
  searchTrainers
} from "../../redux/actions/trainers";
import { load_user } from "../../redux/actions/auth";
import { checkClientLimit, FREE_CLIENTS } from "../../constants/utils";
import { useHistory } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import { IoPeopleOutline } from "react-icons/io5";
import axios from "axios";
import { REACT_APP_API_URL } from "../../env";
import BootstrapInput from "../common/Input";
import CustomSelect from "../common/DropDown";
import DebounceSelect from "../loaders/DebounceSelect";

const { Text } = Typography;

const CreateClientForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [createAccount, setCreateAccount] = useState(true);
  const formikRef = useRef();
  const { user, Theme } = useSelector((state) => state.auth);
  const [reachedLimit, setReachedLimit] = useState(false);
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );
  const { clientStatus, profiles } = useSelector((state) => state.clients);
  const { trainers } = useSelector((state) => state.trainers);
  const [type, setType] = useState("");

  const profileOptions = profiles.map((profile) => ({
    value: profile.id,
    label: profile.name
  }));

  const history = useHistory();

  const [noTrainerError, setNoTrainerError] = useState(false);
  const [gender, setGender] = useState("M");

  const formatDateString = (text) => {
    const cleaned = text.replace(/[^0-9]/g, "");
    let formatted = cleaned;

    if (cleaned.length > 2) {
      formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2)}`;
    }
    if (cleaned.length > 4) {
      formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(
        2,
        4
      )}/${cleaned.slice(4)}`;
    }

    return formatted;
  };

  const convertToISODate = (date) => {
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  /*
  const handleSubmit = async (values, { resetForm }) => {
    const data = new FormData();
    const dob = convertToISODate(values.dob);
    if (values.dob) {
      data.append("dob", dob);
    }
    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    if (values.email !== "") {
      data.append("email", values.email.toLowerCase());
    }
    values.age != "" && data.append("age", values.age);
    values.weight != null && data.append("weight", values.weight);
    values.height_feet != null &&
      data.append("height_feet", values.height_feet);
    values.height_inches != null &&
      data.append("height_inches", values.height_inches);
    values.height_cm != null && data.append("height_cm", values.height_cm);
    values.weight_kg != null && data.append("weight_kg", values.weight_kg);
    data.append("gender", gender);
    if (values.trainer_id) {
      data.append("trainer_id", values.trainer_id);
    }
    if (trainers.length > 1 && !values.trainer_id) {
      setNoTrainerError(true);
      return;
    }
    if (values.profile_id) {
      const selectedProfile = profiles.find(profile => profile.id === values.profile_id);
      data.append("range_profile", JSON.stringify(selectedProfile.range_profile));
    }
    // Dispatch the action to create the client
    dispatch(createClient(data, createAccount));
  };
  */

  const handleSubmit = async (values, { resetForm }) => {
    const data = {};

    if (values.dob) {
      data.dob = convertToISODate(values.dob);
    }

    // Add form values to the data object
    data.first_name = values.first_name;
    data.last_name = values.last_name;

    if (values.email !== "") {
      data.email = values.email.toLowerCase();
    }

    if (values.age !== "") {
      data.age = values.age;
    }

    if (values.weight !== null) {
      data.weight = values.weight;
    }

    if (values.height_feet !== null) {
      data.height_feet = values.height_feet;
    }

    if (values.height_inches !== null) {
      data.height_inches = values.height_inches;
    }

    if (values.height_cm !== null) {
      data.height_cm = values.height_cm;
    }

    if (values.weight_kg !== null) {
      data.weight_kg = values.weight_kg;
    }
    data.gender = gender;

    if (values.trainer_id) {
      data.trainer_id = values.trainer_id;
    }

    if (trainers.length > 1 && !values.trainer_id) {
      setNoTrainerError(true);
      return;
    }
    if (values.type) {
      data.type = values.type;
    }
    if (values.profile_id) {
      const selectedProfile = profiles.find(
        (profile) => profile.id === values.profile_id
      );
      data.range_profile = selectedProfile.range_profile; // No need to stringify here
    } else if (values.profile_id == null && profiles.length === 1) {
      const firstProfile = profiles[0];
      values.profile_id = firstProfile.id;
      data.range_profile = firstProfile.range_profile;
    }

    // Dispatch the action with the data object as JSON
    dispatch(createClient(data, createAccount));
  };

  const createStripePortal = async () => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token
      }
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/stripeportal/`,
      null,
      config
    );
    if (res.status === 200) {
      window.location.href = res.data.portal_url;
    }
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    checkClientLimit(compedMembership, setReachedLimit, user);
    dispatch(getTrainers());
    dispatch(getProfiles());
  }, []);

  useEffect(() => {
    checkClientLimit(compedMembership, setReachedLimit, user);
  }, [subscriptionStatus, user]);

  useEffect(() => {
    if (clientStatus === 201) {
      dispatch(getClients());
      dispatch(load_user());
      setTimeout(() => {
        history.push("/");
        formikRef.current?.resetForm();
        dispatch(resetClientStatus());
        closeModal(false);
      }, 500);
    }
  }, [clientStatus]);

  return (
    <Col
      align="middle"
      justify="center"
      bordered={true}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative"
      }}
      className="criclebox h-full"
    >
      {!reachedLimit ? (
        <div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              age: "",
              dob: "",
              weight: null,
              height_feet: null,
              height_inches: null,
              weight_kg: null,
              height_cm: null,
              trainer_id: null,
              profile_id: null,
              type: ""
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object().shape({
              first_name: yup.string().required("Required"),
              last_name: yup.string().required("Required"),
              email: createAccount
                ? yup
                    .string()
                    .email("Must be a valid email address")
                    .required("Required if account is being created")
                : yup.string().email("Must be a valid email address"),

              weight: yup
                .number()
                .typeError("Must be a number")
                .min(1, "1-999")
                .max(999, "1-999")
                .nullable(),
              height_feet: yup
                .number()
                .typeError("Must be a number")
                .max(8, "Max 8 ft")
                .nullable(),
              height_inches: yup
                .number()
                .typeError("Must be a number")
                .max(12, "Max 12 in")
                .nullable(),
              dob: yup
                .string()
                .matches(
                  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
                  "MM/DD/YYYY"
                )
                .required("Required")
              // trainer_id: yup.number().when("user?.profile?.account_type", {
              //   is: (value) => value === 2,
              //   then: (schema) => schema.required("Required"),
              //   otherwise: (schema) => schema,
              // }),
            })}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue
            }) => (
              <Col
                align="middle"
                justify="center"
                className={css(styles.registerContainer)}
              >
                <Col
                  align="middle"
                  justify="center"
                  className={css(styles.block, styles.center)}
                >
                  <div className={css(styles.title)}>
                    <p
                      className={css(styles.titleText)}
                      style={{ color: Theme.TEXT }}
                    >
                      Add New {user?.profile.client_label}
                    </p>
                  </div>

                  <Row
                    align="middle"
                    justify="center"
                    className={css(styles.row)}
                    style={{
                      bottom: 25,
                      marginBottom: "2vw"
                    }}
                  >
                    <div className={css(styles.column, styles.margin5)}>
                      <BootstrapInput
                        value={values.first_name}
                        placeholder="First Name"
                        onChange={handleChange("first_name")}
                        // error={touched.first_name && errors.first_name}
                        width={"9vw"}
                      />
                      <Col
                        align="start"
                        justify="center"
                        style={{ left: "3vw", position: "relative" }}
                      >
                        <Text
                          style={{
                            color: "red",
                            position: "absolute",
                            textAlign: "center",
                            fontSize: ".65vw"
                          }}
                        >
                          {touched.first_name && errors.first_name}
                        </Text>
                      </Col>
                    </div>
                    <div
                      className={css(styles.column, styles.margin5)}
                      style={{ marginLeft: "1vw" }}
                    >
                      <BootstrapInput
                        value={values.last_name}
                        placeholder="Last Name"
                        onChange={handleChange("last_name")}
                        width={"9vw"}
                        // error={touched.last_name && errors.last_name}
                      />
                      <Col
                        align="start"
                        justify="center"
                        style={{
                          left: "3vw",
                          position: "relative"
                        }}
                      >
                        <Text
                          style={{
                            color: "red",
                            position: "absolute",
                            textAlign: "center",
                            fontSize: ".65vw"
                          }}
                        >
                          {touched.last_name && errors.last_name}
                        </Text>
                      </Col>
                    </div>
                  </Row>
                  <Col
                    align="middle"
                    justify="center"
                    style={{
                      bottom: 25
                    }}
                  >
                    <BootstrapInput
                      value={values.email}
                      placeholder="Email"
                      onChange={handleChange("email")}
                      // error={touched.email && errors.email}
                      width={"19vw"}
                    />
                    <Col
                      align="start"
                      justify="center"
                      style={{ left: "3vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: "red",
                          position: "absolute",
                          textAlign: "center",
                          fontSize: ".65vw"
                        }}
                      >
                        {touched.email && errors.email}
                      </Text>
                    </Col>
                  </Col>
                  <Row
                    justify={"center"}
                    align={"middle"}
                    style={{
                      bottom: ".5vw",
                      marginBottom: ".25vw",
                      position: "relative"
                    }}
                  >
                    <div
                      className={css(styles.column)}
                      style={{ marginRight: "1vw" }}
                    >
                      <BootstrapInput
                        value={values.dob}
                        placeholder="Date of Birth"
                        onChange={(e) =>
                          setFieldValue("dob", formatDateString(e.target.value))
                        }
                        width={"9vw"}
                      />
                      <Col
                        align="start"
                        justify="center"
                        style={{
                          left: "3vw",
                          position: "relative"
                        }}
                      >
                        <Text
                          style={{
                            color: "red",
                            position: "absolute",
                            textAlign: "center",
                            fontSize: ".65vw"
                          }}
                        >
                          {touched.dob && errors.dob}
                        </Text>
                      </Col>
                    </div>
                    <CustomSelect
                      defaultValue="M"
                      options={[
                        { value: "M", label: "Male" },
                        { value: "F", label: "Female" }
                      ]}
                      value={gender}
                      onChange={(value) => setGender(value)}
                      width="9vw"
                      lightModeBackground="#f1f1fb"
                      darkModeBackground="#000"
                    />
                  </Row>
                  {user?.profile?.measurement === "Imperial" ? (
                    <Row
                      justify={"center"}
                      align={"middle"}
                      className={css(styles.row)}
                      style={{ bottom: 25, marginBottom: "1vw" }}
                    >
                      <div className={css(styles.column)}>
                        <BootstrapInput
                          value={values.weight}
                          placeholder="Weight"
                          onChange={handleChange("weight")}
                          width={"5.75vw"}
                          error={touched.weight && errors.weight}
                        />
                      </div>
                      <div
                        className={css(styles.column)}
                        style={{ marginLeft: ".75vw", marginRight: ".75vw" }}
                      >
                        <BootstrapInput
                          value={values.height_feet}
                          placeholder="Height (ft)"
                          onChange={handleChange("height_feet")}
                          width={"5.75vw"}
                          error={touched.height_feet && errors.height_feet}
                        />
                      </div>
                      <div className={css(styles.column)}>
                        <BootstrapInput
                          value={values.height_inches}
                          placeholder="Height (in)"
                          onChange={handleChange("height_inches")}
                          width={"5.75vw"}
                          error={touched.height_inches && errors.height_inches}
                        />
                      </div>
                    </Row>
                  ) : (
                    <Row
                      justify={"center"}
                      align={"middle"}
                      className={css(styles.row)}
                      style={{ bottom: 25, marginBottom: "1vw" }}
                    >
                      <div className={css(styles.column)}>
                        <BootstrapInput
                          value={values.weight_kg}
                          placeholder="Weight (kg)"
                          onChange={handleChange("weight_kg")}
                          width={"9vw"}
                          error={touched.weight_kg && errors.weight_kg}
                        />
                      </div>
                      <div
                        className={css(styles.column)}
                        style={{ marginLeft: "1vw" }}
                      >
                        <BootstrapInput
                          value={values.height_cm}
                          placeholder="Height (cm)"
                          onChange={handleChange("height_cm")}
                          width={"9vw"}
                          error={touched.height_cm && errors.height_cm}
                        />
                      </div>
                    </Row>
                  )}
                  {(user?.profile.account_type == 2 || trainers.length > 1) && (
                    <Row
                      justify={"center"}
                      className={css(styles.row)}
                      style={{ bottom: 25 }}
                    >
                      <div className={css(styles.column)}>
                        {trainers.length > 0 ? (
                          <>
                            <DebounceSelect
                              style={{ width: "19vw", bottom: 3 }}
                              showSearch
                              suffixIcon={null}
                              placeholder={
                                <span
                                  style={{
                                    color:
                                      Theme.BACKGROUND === "#000"
                                        ? "#fff"
                                        : "#000",
                                    fontSize: ".65vw",
                                    top: ".05vw",
                                    position: "relative",
                                    fontWeight: "normal"
                                  }}
                                >
                                  Search Trainers
                                </span>
                              }
                              optionFilterProp="children"
                              onChange={handleChange("trainer_id")}
                              fetchOptions={fetchTrainers}
                              debounceTimeout={800}
                            />
                          </>
                        ) : (
                          <Text
                            style={{
                              color: "red",
                              top: "15%",
                              position: "relative"
                            }}
                          >
                            Create a trainer in your admin console before
                            creating {user?.profile.client_label.toLowerCase()}s
                          </Text>
                        )}
                        {noTrainerError && (
                          <p className={css(styles.errors)}>Required</p>
                        )}
                      </div>
                    </Row>
                  )}
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ marginTop: "3.5%" }}
                  >
                    {profiles.length > 1 && (
                      <Row
                        justify={"center"}
                        className={css(styles.row)}
                        style={{ bottom: 25 }}
                      >
                        <CustomSelect
                          width={"9vw"}
                          showSearch
                          suffixIcon={null}
                          options={profileOptions}
                          placeholder={
                            <span
                              style={{
                                color:
                                  Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                                fontSize: ".7vw",
                                top: ".05vw",
                                position: "relative",
                                fontWeight: "normal"
                              }}
                            >
                              Choose Range Profile
                            </span>
                          }
                          size={"large"}
                          lightModeBackground="#f1f1fb"
                          darkModeBackground="#000"
                          onChange={(value) =>
                            setFieldValue("profile_id", value)
                          }
                        ></CustomSelect>
                      </Row>
                    )}
                    <Col style={{ marginLeft: "5%" }}>
                      <CustomSelect
                        width={"9vw"}
                        showSearch
                        suffixIcon={null}
                        options={[
                          { value: "athlete", label: "Athlete" },
                          { value: "fitness", label: "Fitness" },
                          { value: "other", label: "Other" }
                        ]}
                        onChange={(value) => setFieldValue("type", value)}
                        placeholder={
                          <span
                            style={{
                              color:
                                Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                              fontSize: ".7vw",
                              top: ".05vw",
                              position: "relative",
                              fontWeight: "normal"
                            }}
                          >
                            Choose Type
                          </span>
                        }
                        size={"large"}
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                      ></CustomSelect>
                    </Col>
                  </Row>
                  <Row
                    justify={"center"}
                    className={css(styles.row, styles.center)}
                    style={{ width: "100%", left: "2.5%", marginTop: "5%" }}
                  >
                    <Text
                      className={css(styles.checkboxText)}
                      style={{ color: Theme.TEXT }}
                    >
                      <Checkbox
                        type="checkbox"
                        style={{ width: "5vw", height: "5vw" }}
                        className={css(styles.checkbox)}
                        checked={createAccount}
                        onChange={() => setCreateAccount(!createAccount)}
                      />
                      Create account for{" "}
                      {user?.profile.client_label.toLowerCase()} to view and
                      take assessments remotely
                    </Text>
                  </Row>

                  <button
                    className={css(styles.createButton)}
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <div className={css(styles.buttonGradient)}>
                      <p className={css(styles.buttontext)}>Submit</p>
                    </div>
                  </button>
                  {clientStatus > 399 && (
                    <div
                      className={css(styles.row, styles.center)}
                      style={{ top: 25 }}
                    >
                      <p
                        className={css(styles.errorText)}
                        style={{ color: "red" }}
                      >
                        Error - Please Verify All Fields and Ensure Unique Email
                      </p>
                    </div>
                  )}
                </Col>
              </Col>
            )}
          </Formik>
        </div>
      ) : (
        <Col align="center" justify="center">
          <Row align="center">
            <IoPeopleOutline
              style={{
                fontSize: "2vw",
                color: Theme.PRIMARY,
                marginBottom: "3%"
              }}
            />
          </Row>
          <Row align="center">
            <Text
              className={css(styles.movementTitle)}
              style={{ color: Theme.TEXT }}
            >
              {user?.profile.client_label} Limit Reached
            </Text>
          </Row>
          <Row align="center">
            <Text
              className={css(styles.clientDescr)}
              style={{ color: Theme.TEXT2 }}
            >
              Upgrade subscription in your account settings to create additional{" "}
              {user?.profile.client_label.toLowerCase()}s
            </Text>
          </Row>
        </Col>
      )}
    </Col>
  );
};

export default CreateClientForm;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center"
  },
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },
  input: {
    backgroundColor: Theme.COLORS.WHITE,
    borderBottomColor: "gray",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw"
  },
  smallInput: {
    backgroundColor: Theme.COLORS.WHITE,

    // borderBottomColor: "black",
    // borderColor: "white",
    // borderWidth: 5,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
    ":focus": {
      borderColor: Theme.COLORS.WHITE // Change the border color as needed
    }
  },
  checkboxText: {
    fontSize: ".75vw",
    width: "80%"
  },
  registerContainer: {
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5,
    fontSize: ".75vw"
    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw"
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%"
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 62,
    borderWidth: 0,
    alignSelf: "center",
    top: 20
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",

    textAlign: "center"
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",

    textAlign: "center"
  }
});
