import React from "react";
import { Button, Card, Col, Input, Modal, Row } from "antd";

import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import BootstrapInput from "../common/Input";
import { getSingleClient } from "../../redux/actions/clients";
import RenderProfileSection from "./RenderProfileSection";
import { PlusCircleOutlined } from "@ant-design/icons";
import useProfileFieldManager from "./useProfileFieldManager";

const DetailsGoals = ({
  editing,
  onUpdateField,
  setEditing,
  handleSave,
  profile,
  error,
  setProfile
}) => {
  const dispatch = useDispatch();
  const { Theme, user } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const {
    isModalVisible,
    setIsModalVisible,
    newFieldName,
    setNewFieldName,
    newFieldValue,
    setNewFieldValue,
    handleAddField,
    handleSaveField
  } = useProfileFieldManager(profile, setProfile, handleSave);

  return (
    <Row style={{ width: "79vw" }}>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[
          <Button type="primary" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              handleSaveField();
            }}
          >
            Save
          </Button>
        ]}
      >
        <Text
          style={{
            color: Theme.TEXT,
            paddingTop: "1vw",
            marginBottom: "1vw",
            fontSize: ".9vw"
          }}
        >
          Add New Field
        </Text>
        <BootstrapInput
          placeholder="Field Name"
          value={newFieldName}
          onChange={(e) => setNewFieldName(e.target.value)}
          style={{ marginBottom: "1vw", marginTop: "1vw" }}
        />
        <BootstrapInput
          placeholder="Initial Value (Optional)"
          value={newFieldValue}
          onChange={(e) => setNewFieldValue(e.target.value)}
        />
      </Modal>
      <Card
        style={{
          bottom: 75,
          width: "48%",
          marginRight: "1vw",
          // minHeight: "100vh",
          zIndex: 100,
          position: "relative",
          background: Theme.CARD,
          borderWidth: 0,
          right: "8.75vw"
        }}
      >
        <Row align={"start"}>
          <Text
            style={{
              fontSize: "1vw",
              color: Theme.TEXT,
              alignSelf: "flex-start",
              fontWeight: 800
            }}
          >
            Personal Information
            <PlusCircleOutlined
              style={{
                marginLeft: ".5vw",
                fontSize: "1vw",
                color: Theme.BLUE,
                cursor: "pointer"
              }}
              onClick={() => handleAddField("goals_and_preferences")}
            />
          </Text>
          {!editing ? (
            <button
              textStyle={{ fontSize: "3vw" }}
              style={{
                borderWidth: 0,
                padding: 3,
                width: "5vw",
                borderRadius: 5,

                color: "white",
                fontSize: ".65vw",
                fontWeight: "bold",
                position: "absolute",
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                height: "1.3vw",
                cursor: "pointer",
                marginLeft: ".5vw",
                zIndex: 999,
                right: 10,
                top: 10
              }}
              onClick={() => {
                setEditing(true);
              }}
            >
              Edit Profile
            </button>
          ) : (
            <Row style={{ position: "absolute", right: 10, top: 10 }}>
              <button
                textStyle={{ fontSize: "3vw" }}
                style={{
                  borderWidth: 0,
                  padding: 3,
                  width: "5vw",
                  borderRadius: 5,

                  color: "white",
                  fontSize: ".65vw",
                  fontWeight: "bold",

                  background: "black",
                  height: "1.3vw",
                  cursor: "pointer",
                  marginLeft: ".5vw",
                  zIndex: 999
                }}
                onClick={() => {
                  dispatch(getSingleClient(client?.id));
                  setEditing(false);
                }}
              >
                Cancel
              </button>
              <Col>
                <button
                  textStyle={{ fontSize: "3vw" }}
                  style={{
                    borderWidth: 0,
                    padding: 3,
                    width: "5vw",
                    borderRadius: 5,

                    color: "white",
                    fontSize: ".65vw",
                    fontWeight: "bold",

                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    height: "1.3vw",
                    cursor: "pointer",
                    marginLeft: ".5vw",
                    zIndex: 999
                  }}
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>

                {error && (
                  <Col>
                    <Text style={{ color: "red", fontSize: ".65vw" }}>
                      Error Saving Profile
                    </Text>
                  </Col>
                )}
              </Col>
            </Row>
          )}
        </Row>

        <Row
          style={{
            gap: "1vw",
            marginTop: ".5vw",
            textAlign: "left",
            paddingBottom: "1vw"
          }}
        >
          <RenderProfileSection
            section={profile?.goals_and_preferences || []}
            sectionKey="goals_and_preferences"
            editing={editing}
            onUpdateField={onUpdateField}
          />
        </Row>
      </Card>

      {/* Editable Sport Details */}
      <Card
        style={{
          bottom: 75,
          width: "48%",
          zIndex: 100,
          position: "relative",
          background: Theme.CARD,
          borderWidth: 0,
          right: "8.75vw"
        }}
      >
        {client?.type === "athlete" ? (
          <Col align={"start"}>
            <Text
              style={{
                fontSize: "1vw",
                color: Theme.TEXT,
                alignSelf: "flex-start",
                fontWeight: 800
              }}
            >
              Sport Details
              <PlusCircleOutlined
                style={{
                  marginLeft: ".5vw",
                  fontSize: "1vw",
                  color: Theme.BLUE,
                  cursor: "pointer"
                }}
                onClick={() => handleAddField("sport_details")}
              />
            </Text>
            <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
              <RenderProfileSection
                section={profile?.sport_details || []}
                sectionKey="sport_details"
                editing={editing}
                onUpdateField={onUpdateField}
              />
            </Row>
          </Col>
        ) : (
          <Col align={"start"}>
            <Text
              style={{
                fontSize: "1vw",
                color: Theme.TEXT,
                alignSelf: "flex-start",
                fontWeight: 800
              }}
            >
              Lifestyle
              <PlusCircleOutlined
                style={{
                  marginLeft: ".5vw",
                  fontSize: "1vw",
                  color: Theme.BLUE,
                  cursor: "pointer"
                }}
                onClick={() => handleAddField("lifestyle")}
              />
            </Text>
            <Row style={{ gap: "1vw", marginTop: ".5vw", textAlign: "left" }}>
              <RenderProfileSection
                section={profile?.lifestyle || []}
                sectionKey="lifestyle"
                editing={editing}
                onUpdateField={onUpdateField}
              />
            </Row>
          </Col>
        )}
      </Card>
    </Row>
  );
};

export default DetailsGoals;
